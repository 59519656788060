import { Component, EventEmitter, ElementRef, Input, Output, ViewChild, OnInit } from '@angular/core';

import { DxFormComponent, DxTreeListComponent } from 'devextreme-angular';
import { alert } from 'devextreme/ui/dialog';

import { VendorService } from './vendor.service';
import { CmmService } from './../../../menu/cmm/cmm.service';
import * as _ from 'lodash';

/** Daum Address Popup Config Start **********************************************/
declare var daum: any;
const url = "https://ssl.daumcdn.net/dmaps/map_js_init/postcode.v2.js?autoload=false";
/** Daum Address Popup Config End ************************************************/

@Component({
    selector: 'vendor',
    templateUrl: './vendor.component.html',
    styleUrls: ['./vendor.component.scss'],
})
export class VendorComponent  implements OnInit {
    @ViewChild('formVend') formVend: DxFormComponent;
    @ViewChild('formTree') tree: DxTreeListComponent;

    private _isPopupVisible = false;
    private _isCandidate = false;
    vendorTitle:any = '거래처등록(일회성)';
    // 양방향 바인딩 처리 (속성명을 바꾸면 안됨.)
    @Input()
    get isPopupVisible(): boolean {
        return this._isPopupVisible;
    }
    set isPopupVisible(value: boolean) {
        if(value){
            if(this.formVend){
                this.formBuilder();
                this.tree.instance.clearSelection();
            }
        }
        this._isPopupVisible = value;
        this.isPopupVisibleChange.emit(this._isPopupVisible);
    };
    @Input()
    get isCandidate(): boolean {
        return this._isCandidate;
    }
    set isCandidate(value: boolean) {
        this._isCandidate = value;
        if(value){
            this.vendorTitle = '거래처등록(Candidate)';
        }else{
            this.vendorTitle = '거래처등록(일회성)';
        }
    };
    @Output() isPopupVisibleChange = new EventEmitter<boolean>();

    /**
     * 폼과 바인딩 되는 속성
     */
    formData = {};

    /**
     * 구분 룩업 리스트와 바인딩 되는 속성
     */
    // TODO: 공통코드 처리
    boardType: any[] = [
        { BRD_TYPE: 'Q', BRD_TYPE_NAME: '문의' },
        { BRD_TYPE: 'S', BRD_TYPE_NAME: '제안' },
    ];

    /**
     * QNA 팝업폼의 높이와 바인딩 되는 메서드
     */
    getVendPopupHeight(): number {
        return window.innerHeight * 0.9;
    }

    /**
     * 개인정보 수집 동의 체크박스의 validationCallback과 바인딩 되는 메서드
     * @param e
     */
    isValidPopupPiAgree(e: any): boolean {
        return e.value === true;
    }

    searchFormData: any;
    formKeyData: any;

    vBanks: any[];

    saveFlag: any = 'update';

    zipCdOptions: any;

    treeSelectedRows: any[];

    /** Daum Address Popup Start */
    public styleClass: String;
    private el: ElementRef;
    private debug: false;
    @Output() addressResult = new EventEmitter<Object>();
    @Input() options: any;
    /** Daum Address Popup End */

    constructor(
        private service: VendorService,
        private _cmmService: CmmService,
        el: ElementRef /* Daum Address Popup */
    ) {
        this.el = el; /* Daum Address Popup */
        this.zipCdOptions = {
            maxLength: 6,
            buttons: [{
                location: "after",
                name: "검색",
                options: {
                    text: '검색',
                    onClick: e => { this.openDaumApi(); },
                }
            }]
        }
    }

    ngOnInit(): void {
        //공통코드 생성
        this.cmmBuilder();
        //formData Setting
        this.formBuilder();
        //조회
        this.retrieve();
        /** Daum Address Popup Start ***************************************************/
        this.debug = this.options && this.options.debug ? this.options.debug : false;
        this.styleClass = this.options && this.options.class ? Array.isArray(this.options.class) ? this.options.class.join(" ") : this.options.class : '';
        this.loadDaumApi().then(() => {
            this.print('Daum api has been loaded.');
        });
        /** Daum Address Popup End ****************************************************/
    }

    /**
     * 공통코드 세팅
     * @param form
     */
    async cmmBuilder() {
        //거래은행(21||18)
        this.vBanks = await this._cmmService.getCmmCodeValues('2118');
    }

    /**
     *
     * @param form
     */    
    formBuilder(){
        this.formVend.formData = {
            VCODE: '',
            BIZ_NO: '',
            PASS_NO: '',
            VNAME1: '',
            VNAME2: '',
            VNAME3: '',
            VBANK: '',
            VRECD: '01', // Unit Price
            VTAXCD: '01', // 세금계산서
            MEMGUBUN: '1', // 일회성거래처
            USE_YN: 'Y', // 사용
            GUBUN1: 'O', // 자가
            GUBUN2: 'O', // 자가
            SALE_ITEM: ''
        };
    }

    /**
     * 등록버튼 클릭시
     */
    onInsertButtonClick = (): void => {
        let _thisForm = this.formVend;
        let _formKey = _thisForm.accessKey;
        let _formData = _thisForm.formData;

        //취급품목 필수입력 체크
        if(this.treeSelectedRows === undefined || this.treeSelectedRows.length === 0){
            alert('취급품목은 필수입력 입니다.','알림');
            return;
        }

        // {CODE:'1',NAME:'일회성거래처'},{CODE:'2',NAME:'SAP Supplier'},{CODE:'3',NAME:'Candidate'}
        if(this._isCandidate){
            _formData.MEMGUBUN = '3';
        }else{
            _formData.MEMGUBUN = '1';
        }

        if(_formData.BIZ_NO === '' || _formData.BIZ_NO === undefined){
            alert('사업자등록번호는 필수입력 입니다.','알림');
            return;
        }
        if(_formData.VNAME1 === '' || _formData.VNAME1 === undefined){
            alert('상호명(한글)은 필수입력 입니다.','알림');
            return;
        }
        if(_formData.PASS_NO === '' || _formData.PASS_NO === undefined){
            alert('패스워드는 필수입력 입니다.','알림');
            return;
        }

        this.service.retrieveDuplicate(_formData).then(result => {
            if(result[0].BIZ_CNT > 0){
                alert('이미 등록된 사업자번호 입니다.<br>사업자 번호를 확인 하시기 바랍니다.','알림');
                return;
            }else{
                delete _formData.WORKER_TOT;
                let insertParam = _.cloneDeep(_formData);
                this.service.insert(insertParam, this.treeSelectedRows).then(result => {
                    alert('저장되었습니다.', '알림');
                    this.isPopupVisible = false;
                    //window.location.reload();
                });
            }      
        });
    }

    /**
     * 닫기버튼 클릭시
     */
    onCloseButtonClick = (): void => {
        this.isPopupVisible = false;
        //window.location.reload();
    }

    /** 취급품목 선택 */
    selectionChange(e) {
        this.treeSelectedRows = e.selectedRowsData;
    }

    retrieve = () => {
        /** 거래처정보 조회 */
        this.searchFormData = {
            SEARCH_VALUE : ''
        }
        this.service.retrieve(this.searchFormData).then(result => {
            this.saveFlag = 'update';
            if (result.length > 0) {
                this.formVend.formData = result[0];
                this.formKeyData = {
                    VCODE: result[0].VCODE
                };
            } else {
                this.formKeyData = {
                    VCODE: ''
                };
            }
            this.formVend.accessKey = this.formKeyData;
            this.formVend.instance.getEditor('VCODE').focus();
            /** 취급품목 조회 */
            this.service.retrieveItem(this.formVend.accessKey).then(result => {
                this.tree.instance.option('dataSource', result);
                this.tree.instance.clearSelection();
                let itKeys: any[] = [];
                result.forEach(e => {
                    if (e.USE_YN === 'Y') {
                        itKeys.push(e.ITKEY);
                    }
                })
                this.tree.instance.selectRows(itKeys, true);
            });
        });
    }

    /**************************************************************************************************
     * Daum Address Popup
     **************************************************************************************************/
    daumAddressOptions = {
        class: ['btn', 'btn-primary']
    };

    setDaumAddressApi(data) {
        // 여기로 주소값이 반환
        this.formVend.instance.updateData({ VZIPCD: data.zip, VADDR: data.addr }); //form에서는 updateData로 사용하면된다.
    }

    private print(msg) {
        if (this.debug) {
            //console.log(`[${Math.floor(new Date().getTime()/1000)}]`, msg);
        }
    }

    private daumApiCallback(data) {
        this.print(data);
        let fullAddr = '', extraAddr = '', engAddr = '', zipCode = '';
        if (data.userSelectedType === 'R') {
            fullAddr = data.roadAddress;
            zipCode = data.zonecode;
            engAddr = data.roadAddressEnglish;
            if (data.bname !== '') {
                extraAddr += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            fullAddr += (extraAddr !== '' ? ' (' + extraAddr + ')' : '');
        } else {
            fullAddr = data.jibunAddress;
            zipCode = data.postcode;
            engAddr = data.jibunAddressEnglish;
        }

        // this.addressResult.emit({
        //   zip: zipCode,
        //   addr: fullAddr,
        //   addrEng: engAddr
        // });

        this.setDaumAddressApi({
            zip: zipCode,
            addr: fullAddr,
            addrEng: engAddr
        });
    }

    openDaumApi(): Promise<any> {
        return new Promise((resolve, reject) => {

            let self = this;
            if (!this.options || (!this.options.type || this.options.type === 'popup')) {
                daum.postcode.load(() => {
                    new daum.Postcode({
                        oncomplete: (data) => {
                            self.daumApiCallback(data),
                                resolve(data);
                        }
                    }).open();
                });
            } else {
                if (!this.options.target) {
                    this.print('ERROR: Parent Component does not have a target element.');
                    return false;
                }

                const $target = this.el.nativeElement.parentElement.querySelector(`#${this.options.target}`);
                this.print($target);
                switch (this.options.type) {
                    case 'layer':
                        let width = this.options.width || 300;
                        let height = this.options.height || 460;
                        let border = this.options.border || 5;
                        daum.postcode.load(() => {
                            new daum.Postcode({
                                oncomplete: (data) => {
                                    self.daumApiCallback(data),
                                        resolve(data);
                                },
                                onclose: () => $target.style.display = 'none',
                                width: '100%',
                                height: '100%'
                            }).embed($target);
                        });
                        $target.style.display = 'block';
                        $target.style.width = `${width}px`;
                        $target.style.height = `${height}px`;
                        $target.style.border = `${border}px solid`;
                        $target.style.left = `${(((window.innerWidth || document.documentElement.clientWidth) - width) / 2 - border)}px`;
                        $target.style.top = `${(((window.innerHeight || document.documentElement.clientHeight) - height) / 2 - border)}px`;
                        try {
                            $target.querySelector('#btnCloseLayer').onclick = () => {
                                $target.style.display = 'none';
                            };
                        } catch (e) {
                            this.print(`ERROR: ${e.message}`);
                        }
                        break;
                    case 'inline':
                        let currentScroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
                        daum.postcode.load(() => {
                            new daum.Postcode({
                                oncomplete: (data) => {
                                    self.daumApiCallback(data);
                                    document.body.scrollTop = currentScroll;
                                },
                                onclose: () => $target.style.display = 'none',
                                onresize: (size) => $target.style.height = size.height + 'px',
                                width: '100%',
                                height: '100%'
                            }).embed($target);
                        });
                        $target.style.display = 'block';
                        try {
                            $target.querySelector('#btnFoldWrap').onclick = () => {
                                $target.style.display = 'none';
                            };
                        } catch (e) {
                            this.print(`ERROR: ${e.message}`);
                        }
                        break;
                }
            }
        });
    }

    private loadDaumApi() {
        return new Promise((resolve, reject) => {
            let script = document.createElement('script');
            script.src = url;
            script.type = 'text/javascript';
            script.async = true;
            this.el.nativeElement.appendChild(script);
            resolve(true);
        });
    }    
}
