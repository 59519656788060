import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { Error500Component } from './error-500.component';

@NgModule({
  declarations: [Error500Component],
  imports: [FuseSharedModule, RouterModule],
})
export class Error500Module {}
