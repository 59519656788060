import { version} from '../../package.json';

export const environment = {
    production: true,
    hmr: false,
    isVendor: true,
    isTms: false,
    baseUrl: '',
    version: version
};
