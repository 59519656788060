import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppInitService } from '../../../app-init.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'quick-panel',
  templateUrl: './quick-panel.component.html',
  styleUrls: ['./quick-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickPanelComponent implements OnDestroy {
  date: Date;
  events: any[];
  notes: any[];
  appNote: any;

  settings: any;
  // 현재 배포된 프로그램 버전
  version: string = environment.version;
  release_version: string;
  display_note: any;
  display_noti: any;
  release_date: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   */
  constructor(private _appService: AppInitService) {
    // Set the defaults
    const { RELEASE_VER, BUILD_DATETIME, REMARKS } = this._appService.appInfo;
    if (this._appService.appNoti && this._appService.appNoti.NOTI) {
      const { NOTI } = this._appService.appNoti;
      this.display_noti = NOTI;
    }

    // 서버에 등록된 최신 배포 버전
    this.release_version = RELEASE_VER;
    this.display_note = REMARKS;
    this.release_date = BUILD_DATETIME;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._appService.appNotiChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => this.display_noti = res);
  }

  reload() {
    location.reload(true);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


}
