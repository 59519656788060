import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatDialogModule,
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import { LoginComponent } from './login.component';
import { DxCheckBoxModule, DxFormModule, DxButtonModule } from 'devextreme-angular';
import { PwchangeComponent } from '../pwchange/pwchange.component';

import { OtpModule } from '../otp/otp.module';

@NgModule({
  declarations: [LoginComponent, PwchangeComponent],
  imports: [
    RouterModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FuseSharedModule,
    DxCheckBoxModule,
    OtpModule,
    MatDialogModule,
    DxFormModule,
    DxButtonModule,
  ],
  entryComponents: [
    PwchangeComponent,
  ],
})
export class LoginModule {}
