import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  /*{
    id: 'applications',
    title: 'Applications',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'sys',
        title: 'SYSTEM',
        translate: 'NAV.SYSTEM.TITLE',
        type: 'collapsable',
        children: [
          {
            id: 'menu',
            title: 'MENU',
            translate: 'NAV.SYSTEM.MENU.TITLE',
            type: 'item',
            icon: 'settings',
            url: '/sys/m3-w4438',
          },
        ],
      },
      {
        id: 'sample',
        title: '그리드 샘플',
        type: 'group',
        children: [
          {
            id: 'sample-grid-1',
            title: '그리드 샘플1',
            type: 'item',
            icon: 'grid',
            url: '/grid-sample/sample1',
          },
          {
            id: 'sample-grid-2',
            title: '그리드 샘플2',
            type: 'item',
            icon: 'grid',
            url: '/grid-sample/sample2',
          },
        ],
      },
    ],
  },
  {
    id: 'applications',
    title: '시스템관리',
    type: 'group',
    children: [
      {
        id: 'sys',
        title: 'System Management',
        type: 'group',
        children: [
          {
            id: '4989',
            title: 'System User Control',
            type: 'item',
            icon: 'person',
            url: '/sys/m3-w4438',
          },
          {
            id: '5150',
            title: '그룹별 권한관리',
            type: 'item',
            icon: 'person',
            url: '/sys/m3-w4438',
          },
          {
            id: '7014',
            title: '사용자별 권한관리',
            type: 'item',
            icon: 'person',
            url: '/sys/m3-w4438',
          },
          {
            id: '4438',
            title: '메뉴관리',
            type: 'item',
            icon: 'setting',
            url: '/sys/m3-w4438',
          },
          {
            id: '4919',
            title: '공통코드관리',
            type: 'item',
            icon: 'setting',
            url: '/sys/m3-w4438',
          },
        ],
      },
      {
        id: 'sample',
        title: '그리드 샘플',
        type: 'group',
        children: [
          {
            id: 'sample-grid-1',
            title: '그리드 샘플1',
            type: 'item',
            icon: 'grid',
            url: '/grid-sample/sample1',
          },
          {
            id: 'sample-grid-2',
            title: '그리드 샘플2',
            type: 'item',
            icon: 'grid',
            url: '/grid-sample/sample2',
          },
        ],
      },
    ],
  },*/
];
