import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FuseNavigationService } from '../@fuse/components/navigation/navigation.service';
import { environment } from '../environments/environment';
import { BehaviorSubject, throwError } from 'rxjs';
import { Router } from '@angular/router';


//액세스 토큰 잔여시간 - 1000 * 60 * 30 // 30 : 30분
export const REMAIN_TIME = 1000 * 60 * 60; // 60 : 1시간

@Injectable()
export class AppInitService {
  appNotiChanged: BehaviorSubject<any>;

  async getAppInfo() {

    try {
      const releaseAppInfoResult: any = await this._httpClient
        .post(`${environment.baseUrl}/v1/prod-version`, { PRODT_CODE: 'PUR', HOTEL_CODE: '1000' }).toPromise();

      if (releaseAppInfoResult && releaseAppInfoResult.rows && releaseAppInfoResult.rows[0]) {
        this.appInfo = releaseAppInfoResult.rows[0];
        //console.log(this.appInfo);
      }
    } catch (err) {
      throw err;
    }

  }

  async getAppNoti() {

    try {
      const appNotiResult: any = await this._httpClient
        .post(`${environment.baseUrl}/v1/app-noti`, { HOTEL_CODE: '1000' }).toPromise();

      if (appNotiResult && appNotiResult.rows && appNotiResult.rows[0]) {
        this.appNoti = appNotiResult.rows[0];
        this.appNotiChanged.next(this.appNoti.NOTI);
        //console.log(this.appNoti);
      } else {
        this.appNotiChanged.next('');
      }
    } catch (err) {
      throw err;
    }
  }

  availableMenu: any[];
  dbType: any;
  logoutTime: any;
  //releaseAppInfo: any = { version: '0.0.0' };
  appInfo: any;
  appNoti: any;

  constructor(private _httpClient: HttpClient) {
    this.availableMenu = [];
    this.appInfo = { RELEASE_VER: '0.0.0' };
    this.appNotiChanged = new BehaviorSubject('');
  }

  init(): Promise<void> {

    return new Promise<void>(async (resolve, reject) => {
      ////console.log('AppInitservice.init() called');

      // available menu 호출
      /* this._auth.currentUserValue; */

      try {

        // 로그인 페이지로 이동해야 하는 경우는 토큰이 없거나 토큰이 유효하지 않을 때이다. 최소한 authMiddleware를 태워서
        // 토큰이 없음을 확인하면 아래 과정을 무시하고 로그인 페이지로 이동시켜야 함.

        // const releaseAppInfoResult: any = await this._httpClient
        //   .post(`${environment.baseUrl}/v1/prod-version`, { PRODT_CODE: 'TMS', HOTEL_CODE: '01' }).toPromise();


        // if (releaseAppInfoResult && releaseAppInfoResult.rows && releaseAppInfoResult.rows[0]) {
        //   this.appInfo = releaseAppInfoResult.rows[0];
        // }

        // await this.getAppInfo(); // version check
        // await this.getAppNoti(); // 공지 check
        const result: any = await this._httpClient
          .post(`${environment.baseUrl}/v1/db-type`, {}).toPromise();
        this.dbType = result.dbType;

        const logoutTime: any = await this._httpClient
          .post(`${environment.baseUrl}/v1/logoutTime`, {}).toPromise();

        this.logoutTime = Number.parseInt(logoutTime.rows[0]['LOGOUT_TIME'] || 0) * 1000;

        if (localStorage.getItem('token') && localStorage.getItem('extratoken')) {
          const tokenCheck: any = await this._httpClient
            .get(`${environment.baseUrl}/v1/token-check`).toPromise();
          if (tokenCheck && tokenCheck.result) {
            const menu: any = await this._httpClient
              .post(`${environment.baseUrl}/v1/availableMenu`, {}).toPromise();
            if (menu) {
              this.availableMenu = menu.rows;
            }
          }
        }
        resolve();
      } catch (err) {
        resolve();
        //reject(err);
      }
    });
  }
}
