import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseNavigationService } from '../../../../@fuse/components/navigation/navigation.service';

@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentComponent implements OnInit {
  /**
   * Constructor
   */
  constructor() {}

  ngOnInit(): void {}
}
