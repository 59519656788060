import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: 'm2-3032',
        loadChildren: './m2-3032/m2-3032.module#M23032Module',
    },
    {
        path: 'm2-3020',
        loadChildren: './m2-3020/m2-3020.module#M23020Module'
    },
    {
        path: 'm2-3021',
        loadChildren: './m2-3021/m2-3021.module#M23021Module'
    },
    {
        path: 'm2-3022',
        loadChildren: './m2-3022/m2-3022.module#M23022Module'
    },
    {
        path: 'm2-300001',
        loadChildren: './m2-300001/m2-300001.module#M2300001Module'
    },
    {
        path: '**',
        redirectTo: '/error-404',
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PoRoutingModule { }
