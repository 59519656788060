import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { map } from 'rxjs/operators';
import { alert } from 'devextreme/ui/dialog';

@Injectable()
export class VendorService {
    list: any[];
    constructor(
        private httpClient: HttpClient,
    ) {

    }

    retrieve(formData: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/bid/m2-9028/m3-w9029/retrievePopup`, formData)
                .pipe(
                    map(res => res.rows)
                )
                .subscribe(
                    rows => {
                        this.list = rows;
                        resolve(this.list);
                    },
                    err => reject(err)
                );
        });
    }

    retrieveItem(formData: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/bid/m2-9028/m3-w9029/retrieveItemPopup`, formData)
                .pipe(
                    map(res => res.rows)
                )
                .subscribe(
                    rows => {
                        this.list = rows;
                        resolve(this.list);
                    },
                    err => reject(err)
                );
        });
    }

    retrieveDuplicate(formData: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/bid/m2-9028/m3-w9029/retrieveDuplicatePopup`, formData)
                .pipe(
                    map(res => res.rows)
                )
                .subscribe(
                    rows => {
                        this.list = rows;
                        resolve(this.list);
                    },
                    err => {
                        //reject(err);
                        alert(err, '오류');
                    }
                );
        });
    }

    insert(data: any, addData: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${environment.baseUrl}/v1/bid/m2-9028/m3-w9029/insertPopup`, { data, addData })
                .subscribe(
                    result => {
                        result.rowsAffected ? resolve(result) : reject(result.errorText);
                    },
                    err => {
                        //console.log(err);
                        return reject(err.message);
                    }
                );
        });
    }
}
