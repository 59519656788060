import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { FuseWidgetModule } from '../../../../@fuse/components';
import { SysMaterialModule } from '../../../menu/sys/sys.material.module';

import { DxFormModule, DxFileUploaderModule, DxPopupModule } from 'devextreme-angular';

import { OtpComponent } from './otp.component';
import { OtpService } from './otp.service';

@NgModule({
    declarations: [
        OtpComponent,
    ],
    imports: [
        CommonModule,

        FuseSharedModule,
        FuseWidgetModule,
        SysMaterialModule,

        DxFormModule,
        DxFileUploaderModule,
        DxPopupModule
    ],
    exports: [
        OtpComponent,
    ],
    providers: [
        OtpService,
    ],
})
export class OtpModule { }
