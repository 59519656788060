import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { FuseWidgetModule } from '../../../../@fuse/components';
import { SysMaterialModule } from '../../../menu/sys/sys.material.module';

import { DxFormModule, DxFileUploaderModule, DxPopupModule, DxTreeListModule } from 'devextreme-angular';

import { VendorComponent } from './vendor.component';
import { VendorService } from './vendor.service';

@NgModule({
    declarations: [
        VendorComponent,
    ],
    imports: [
        CommonModule,

        FuseSharedModule,
        FuseWidgetModule,
        SysMaterialModule,

        DxFormModule,
        DxFileUploaderModule,
        DxPopupModule, 
        DxTreeListModule,
    ],
    exports: [
        VendorComponent,
    ],
    providers: [
        VendorService,
    ],
})
export class VendorModule { }
