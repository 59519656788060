import { NgModule } from '@angular/core';
import { ActionbarComponent } from './actionbar.component';
import {
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatToolbarModule,
} from '@angular/material';
import { FuseSharedModule } from '../../../../@fuse/shared.module';

@NgModule({
  declarations: [ActionbarComponent],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,

    FuseSharedModule,
  ],
  exports: [ActionbarComponent],
})
export class ActionbarModule {}
