export const locale = {
  lang: 'kr',
  data: {
    NAV: {
      COMMON: '공통',
      APPLICATIONS: '애플리케이션',
      SYSTEM: {
        TITLE: '시스템',
        MENU: {
          TITLE: '메뉴관리',
        },
      },
    },
  },
};
