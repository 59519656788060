import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { alert, confirm } from 'devextreme/ui/dialog';

@Injectable({
  providedIn: 'root',
})
export class CommonUIService {
  constructor(private snackBar: MatSnackBar) {}

  showToast(message: string, action = '확인', config?: MatSnackBarConfig): void {
    this.snackBar.open(
      message,
      action,
      config || {
        verticalPosition: 'top',
        horizontalPosition: 'right',
        duration: 3000,
      }
    );
  }

  showInternalError(err) {
    if(err && err.error && err.error.message) {
      alert(`${err.error.message}`, '오류');
    } else if (err && err.message) {
      alert(`${err.message}`, '오류');
    } else {
      alert(`${err}`, '오류');
    }
    return;
  }
}
