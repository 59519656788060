import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { M3W9015Service } from '../../../menu/tms/m2-3111/m3-w9015/m3-w9015.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { PopLoginComponent } from '../../../menu/tms/m2-3111/m3-w9015/pop-login/pop-login.component';
import { CommonUIService } from '../../../common/common-ui.service';
import { alert } from 'devextreme/ui/dialog';
import { AuthService } from '../../../common/auth.service';

@Component({
  selector: 'pwchange',
  templateUrl: './pwchange.component.html',
  styleUrls: ['./pwchange.component.scss']
})
export class PwchangeComponent implements OnInit, AfterViewInit {

  @ViewChild('loginForm')loginForm: DxFormComponent;

  regExpPw: any = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/;
  orgPw: any
  oldPw: any;
  newPw: any;
  confirmPw: any;
  vendorYn: any;
  // hashNewPass: any;
  saveFlag: boolean = true;
  messageString1: string = '현재 비밀번호와 동일한 비밀번호를 입력하였습니다.'
  messageString2: string = '패스워드는 영문, 숫자, 특수문자 포함하여 8자리 이상입니다.'
  messageString3: string = '신규 비밀번호와 일치하지 않습니다.'
  messageString4: string = '현재 비밀번호가 일치하지 않습니다.'

  returnMessage: string;
  validating: boolean = false;

  constructor(
    private _service: M3W9015Service,
    public matDialogRef: MatDialogRef<PopLoginComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public _matDialog: MatDialog,
    private _ui: CommonUIService,
    private _auth: AuthService,
  ) {
    this.keyPressAction = this.keyPressAction.bind(this);
    this.doChangePw = this.doChangePw.bind(this);

  }

  ngOnInit() {
    //console.log('_data===>', this._data);
    // 기존비밀번호를 받는다
    this.orgPw = this._data.USER_PASS;
    this.vendorYn = this._data.VENDOR_YN;
    // this.hashNewPass = this._data.NEW_PASS;

  }

  ngAfterViewInit(): void {
    // //console.log('here');

    // setTimeout(() => this.loginForm.instance.getEditor('USER_ID').focus(), 1000);
    // this.loginForm.instance.getEditor('USER_ID').focus();
  }

  loginFormInitialized(e) {
    //console.log(e);

    setTimeout(() => this.loginForm.instance.getEditor('OLD_PASS').focus(), 1000);

  }

  loginFormContentReady(e) {
    // //console.log(e);
    // //e.component.getEditor('USER_ID').focus();
    // setTimeout(() => this.loginForm.instance.getEditor('USER_ID').focus(), 1000);

  }

  keyPressAction(e) {
    if (e.event.keyCode === 13) {

      setTimeout(this.doChangePw);
    }
  }

  oldPwValueChanged = (e) => {
    this.oldPw = e.value;
  }
  newPwValueChanged = (e) => {
    this.newPw = e.value;
  }
  confirmPwValueChanged = (e) => {
    this.confirmPw = e.value;
  }

  /**
   * doCheck()
   * function 으로 빼서 각각 체인지할때 호출하여 체크하고 저장시 다시 호출
   */

  // doCheck(argType?){

  //   //console.log('pwType====>', argType);
  //   let pwValue = ''
  //   if(argType){
  //     if(argType === 'newPw'){
  //       pwValue = this.newPw;
  //     }else if(argType === 'confirmPw'){
  //       pwValue = this.confirmPw;
  //     }
  //     if(pwValue){
  //       if(pwValue.length < 8){
  //         alert('비밀번호는 8자리 이상을 입력하십시요.', '오류');
  //         return false;
  //       }
  //       if(pwValue.match('[0-9]') === null){
  //         alert('비밀번호는 반드시 숫자를 포함하여야 합니다.','오류');
  //         return false;
  //       }
  //       if(pwValue.match('[^0-9a-zA-Z]') === null){
  //         alert('비밀번호는 반드시 특수문자를 포함하여야 합니다.','오류');
  //         return false;
  //       }
  //       if(pwValue.match('[a-zA-Z]') === null){
  //         alert('비밀번호는 반드시 영문자를 포함하여야 합니다.','');
  //         return false;
  //       }
  //     }
  //   }
  // }

  async doChangePw() {

    // 최종체크
    if (this.loginForm.formData.OLD_PASS === undefined) {
      alert('현재 비밀번호를 입력하세요.', '확인');
      return;
    }
    if (this.loginForm.formData.NEW_PASS === undefined) {
      alert('신규 비밀번호를 입력하세요.', '확인');
      return;
    }
    if (this.loginForm.formData.CONFIRM_PASS === undefined) {
      alert('확인 비밀번호를 입력하세요.', '확인');
      return;
    }

    if (this.newPw) {
      if (this.newPw.length < 8) {
        alert('신규 비밀번호는 8자리 이상을 입력하십시요.', '오류');
        return;
      }
      else if (this.newPw.match('[0-9]') === null) {
        alert('신규 비밀번호는 반드시 숫자를 포함하여야 합니다.', '오류');
        return;
      }
      else if (this.newPw.match('[^0-9a-zA-Z]') === null) {
        alert('신규 비밀번호는 반드시 특수문자를 포함하여야 합니다.', '오류');
        return;
      }
      else if (this.newPw.match('[a-zA-Z]') === null) {
        alert('신규 비밀번호는 반드시 영문자를 포함하여야 합니다.', '오류');
        return;
      }
      else if (/(\w)\1\1\1/.test(this.newPw)) {
        alert('신규 비밀번호는 같은 문자를 연속으로 4번이상 사용하실 수 없습니다.', '오류');
        return;
      }
      else if (!this.checkContinueChar(this.newPw)) {
        alert('신규 비밀번호는 연속된 키보드 배열의 문자나 숫자는 사용하실 수 없습니다.', '오류');
        return;
      }
      else if (!this.otherItemSameCharCheck(this.newPw, this._data.USER_ID)) {
        alert('신규 비밀번호는 사번과 동일한 문자열을 3자 이상 사용하실 수 없습니다.', '오류');
        return;
      }
    }

    // 현재비번이 틀릴경우 (사용안함 : DB단에서 체크해야함)
    // if (this.oldPw !== this.orgPw) {
    //   alert(this.messageString4, '확인');
    //   return;
    // }
    // 신규비번이랑 현재비번이랑 같을 경우
    if (this.newPw === this.oldPw) {
      alert(this.messageString1, '확인');
      return;
    }
    // 신규비번이랑 컨펌이랑 틀릴경우
    if (this.newPw !== this.confirmPw) {
      alert(this.messageString3, '확인');
      return;
    }

    const payload = {
      USER_ID: this._data.USER_ID,
      NEW_PASS: this.loginForm.formData.NEW_PASS,//this.newPw,
      ORG_PASS: this.loginForm.formData.OLD_PASS
    };

    // 요청 파라미터
    // { HOTEL_CODE, OUTLET_CODE, USER_ID, USER_PASS}
    try {
      let tmsPayload:any;
      if(this.vendorYn === 'Y'){
        tmsPayload = await this._auth.changeVendorPw(payload);
      }else{
        tmsPayload = await this._auth.changePw(payload);
      }

      if (tmsPayload) {  // === true
        alert(tmsPayload.message, '확인');
        this.matDialogRef.close();
      } else {
        alert('비밀번호 변경에 실패하였습니다.', '확인');
      }
    } catch (err) {
      if (err) {
        alert(err.error, '비밀번호 변경 실패');
      }
    }
  }

  doCancel() {
    this.matDialogRef.close();
  }

  checkContinueChar(pwd: any): boolean {
    var result = true;
    if (pwd) {
      var continueKeyboard =
        "abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789|890";
      var continueArr = continueKeyboard.split("|");
      continueArr.some(item => {
        result = pwd.indexOf(item) > -1 ? false : true;
        return !result;
      });
    } else {
      result = false;
    }
    return result;
  }

  otherItemSameCharCheck(str, chkStr): boolean {
    if (str) {
      for (var i = 0; i < chkStr.length - 2; i++) {
        var tmp =
          chkStr.charAt(i) + chkStr.charAt(i + 1) + chkStr.charAt(i + 2);
        if (str.indexOf(tmp) > -1) {
          return false;
        }
      }
    } else {
      return false;
    }
    return true;
  }

}
