import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import { Error404Component } from './error-404.component';

@NgModule({
  declarations: [Error404Component],
  imports: [RouterModule, MatIconModule, FuseSharedModule],
})
export class Error404Module {}
