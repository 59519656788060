import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Error404Module } from './errors/404/error-404.module';
import { Error500Module } from './errors/500/error-500.module';
import { LoginModule } from './authentication/login/login.module';
import { LogoutComponent } from './authentication/logout/logout.component';
import { VendorLoginModule } from './authentication/vendor-login/vendor-login.module';
import { VendorLoginCandidateModule } from './authentication/vendor-login-candidate/vendor-login-candidate.module';
import { VendorLogoutComponent } from './authentication/vendor-logout/vendor-logout.component';

@NgModule({
  declarations: [LogoutComponent, VendorLogoutComponent],
  imports: [CommonModule, Error404Module, Error500Module, LoginModule, VendorLoginModule, VendorLoginCandidateModule],
})
export class PagesModule { }
