import { Injectable } from '@angular/core';

@Injectable()
export class ConstantsService {
    readonly toolbarHeight: number = 32;
    readonly contentsTopPadding: number = 24;
    readonly gridTopMargin: number = 20;
    readonly gridTopPadding: number = 20;
    readonly gridBottomPadding: number = 20;
    readonly contentsBottomPadding: number = 24;

    readonly gapHeight: number = this.toolbarHeight + this.contentsTopPadding + this.gridTopMargin +
                                 this.gridTopPadding + this.gridBottomPadding + this.contentsBottomPadding;

    readonly popupToolbarHeight: number = 44;
    readonly popupContentsTopPadding: number = 20;
    readonly popupGridTopMargin: number = 20;
    readonly popupGridTopPadding: number = 20;
    readonly popupGridBottomPadding: number = 20;
    readonly popupContentsBottomPadding: number = 20;
    readonly toolbarBottomHeight: number = 67;

    readonly gapPopupHeight: number = this.popupToolbarHeight + this.popupContentsTopPadding + this.popupGridTopMargin +
                                      this.popupGridTopPadding + this.popupGridBottomPadding + this.popupContentsBottomPadding +
                                      this.toolbarBottomHeight;

}
