import { Component, OnDestroy, OnInit, ViewEncapsulation, AfterViewInit, ChangeDetectorRef, NgZone, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Subject, interval, Observable, BehaviorSubject } from 'rxjs';
import { filter, takeUntil, map, tap, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

import { navigation } from 'app/navigation/navigation';
import { AuthService } from '../../../common/auth.service';
import { CmmService } from '../../../../app/menu/cmm/cmm.service';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { AppInitService, REMAIN_TIME } from '../../../app-init.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { PwchangeComponent } from '../../../pages/authentication/pwchange/pwchange.component';
import { alert, confirm, custom } from 'devextreme/ui/dialog';
import { last } from '@angular/router/src/utils/collection';
import { DxPopupComponent } from 'devextreme-angular';
import { encodeHtml } from 'devextreme/core/utils/string';



@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {

  @ViewChild('currentNavigation') currentNavigation: ElementRef;

  version: string = environment.version;
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  userInfo: any;
  isNotice: boolean = false;

  currentNavigation1: any;
  currentNavigation2: any;
  currentNavigation3: any;
  currentNavigation4: any;

  dbType: string;
  remainTime: number;
  logoutTime: number;
  diaplayRemainTime: string;
  timer: Observable<any> | any;
  isAlert: any;
  releaseTime: DxPopupComponent | any;

  title:any = '개발환경입니다.';
  dbConfigNm:any;
  isLocal:boolean=false;

  get isVendorLogin(): boolean {
    return environment.isVendor;
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {TranslateService} _translateService
   */

  // 서버 정보 조회
  currentHostInfo = JSON.parse(localStorage.getItem('currentHostInfo'));
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseNavigationService: FuseNavigationService,
    private _translateService: TranslateService,
    private _auth: AuthService,
    private _cmmService: CmmService,
    private _router: Router,
    private _appService: AppInitService,
    private _httpClient: HttpClient,
    public _matDialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    // 서버 환경 체크
    if (this.currentHostInfo && this.currentHostInfo.ORIGIN) {
        (async () => {
          let result = await this._cmmService.dbConfig();
          if(result[0].DBTYPE === 'ctp'){
            this.dbConfigNm = '개발DB';
          }else if(result[0].DBTYPE === 'purdbp'){
            this.dbConfigNm = '운영DB';
          }
          this.title = this.currentHostInfo.ORIGIN;
          if(this.currentHostInfo.ORIGIN === 'http://localhost:4200'){
            this.title = `로컬환경(${this.dbConfigNm})입니다.`;
            this.isLocal = true;
          }else if(this.currentHostInfo.ORIGIN === 'http://purdev.parnas.co.kr'){
            this.title = `개발환경(${this.dbConfigNm})입니다. 작업에 주의하세요.`;
            this.isLocal = true;
          }else{
            this.title = '';
            this.isLocal = false;
          }
        })();
    } else {
        this.title = '';
        this.isLocal = false;
    }

    // 변경 사항 체크후 뷰에 반영 처리.
    this.dbType     = _appService.dbType;
    this.logoutTime = _appService.logoutTime;
    this.remainTime = REMAIN_TIME; //this._auth.remainTime;

    this.userInfo = {
      HOTELCODE: '01',
      USERNM: '홍길동',
      USERID: 'item',
      IPNO: '127.0.0.1',
    };

    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us',
      },
      {
        id: 'kr',
        title: '한국어',
        flag: 'kr',
      },
    ];

    this.navigation = navigation;


    this._unsubscribeAll = new Subject();
    this.displayToolbar();
    //Navigation 표시
    this._router.events
      .pipe(
        // tap((x) => {
        //   //console.log('언제 타는지 체크', x);
        // }),
        takeUntil(this._unsubscribeAll),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((x) => {
        this.displayToolbar();
      });


  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    // Version Check

    const checkout = interval(10000).pipe(
      startWith(0),
      takeUntil(this._unsubscribeAll))
      .subscribe(async (rslt: any) => {
        // await this._appService.getAppInfo(); // version check
        // await this._appService.getAppNoti(); // 공지 check
        const { RELEASE_VER } = this._appService.appInfo;
        if (this.version !== RELEASE_VER) {
          this.isNotice = this.version !== RELEASE_VER ? true : false;
        }

        if (this._appService.appNoti && this._appService.appNoti.NOTI) {
          this._fuseConfigService.isEmergency.next(true);
        } else {
          // notice가 활성 상태면 false 처리 한다.
          if (this.isNotice) {
            this.isNotice = false;
          }
        }
      });

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(settings => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    this._fuseConfigService.isEmergency.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(isEmergency => {
      // 긴급공지 출현시 공지 버튼을 활성화 시킨다.
      if (!this.isNotice) {
        this.isNotice = true;
      }
    });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });


    // 사용자 정보 표시
    if (this._auth.currentUserValue) {
      this.userInfo = this._auth.currentUserValue;
    }

    this._auth.refreshTimeSubject.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe(x => {
      this.remainTime = REMAIN_TIME;
      //this.remainTime = 1000 * 30;
    });


    this._fuseNavigationService.onNavigationChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Load the navigation
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    this.timer = interval(1000).pipe(
      takeUntil(this._unsubscribeAll),
      map(x => {

        let minutes = 0;
        let seconds = 0;
        if (this.remainTime > 0) {
          this.remainTime = this.remainTime - 1000;
          let totalSeconds = Math.floor(this.remainTime / 1000);
          minutes = Math.floor(totalSeconds / 60);
          seconds = totalSeconds - minutes * 60;
        }
        return ("00" + minutes).slice(-2) + ':' + ("00" + seconds).slice(-2);
      }));


    this.timer.pipe(
      takeUntil(this._unsubscribeAll))
      .subscribe(x => {
        this.diaplayRemainTime = x;

        if (this.remainTime < 1) {
          if (this.releaseTime) {
            this.releaseTime.hide();
          }
          this._auth.logout();
          location.reload(true);
          return;
        }
        //console.log(this.remainTime, this.logoutTime, this.remainTime < this.logoutTime);
        if (this.remainTime < this.logoutTime && !this.isAlert) {
          this.isAlert = true;
          // this.releaseTime = alert(`로그아웃 ${this.logoutTime/1000} 초 전입니다.`, '확인');
          this.releaseTime = custom({
            title: "잔여시간",
            messageHtml: encodeHtml(`로그아웃 ${this.logoutTime / 1000} 초 전입니다.`),
            buttons: [{
              text: "확인",
              onClick: async (e) => {
                if (this.remainTime > 0) {
                  await this._auth.rebirthToken().toPromise();
                  this.remainTime = REMAIN_TIME
                  //this.remainTime = 1000 * 30
                } else {
                  // 뒤늦게 확인 버튼을 클릭했어도, 잔여시간이 경과되면 로그아웃 처리한다.
                  this._auth.logout();
                }
                this.isAlert = false;
              }
            }]
          });
          this.releaseTime.show();
        }
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  displayToolbar(): void {
    const url = this._router.url;
    if (url.includes('login') || url.includes('main') || url.includes('publishing')) {
      return;
    }
    let fns = this._fuseNavigationService;
    let lv2 = url.substr(url.indexOf('-') + 1,6).replace('/m', '');
    let lv3 = url.substr(url.indexOf('w') + 1);
    this.currentNavigation1 = fns.getNavigationItemParent(lv2).title;
    this.currentNavigation2 = fns.getNavigationItemParent(lv3).title;
    this.currentNavigation3 = fns.getNavigationItem(lv3).title;

    this.currentNavigation4 = `<a href="#"><img src="assets/images/logos/icon_home.png"></a> <img src="assets/images/logos/bullet_linemap.png">
                     <a>`+ this.currentNavigation1 + `</a> <img src="assets/images/logos/bullet_linemap.png">
                     <a>`+ this.currentNavigation2 + `</a> <img src="assets/images/logos/bullet_linemap.png">
                     <span>`+ this.currentNavigation3 + `</span>`;
    setTimeout(() => {
      this.currentNavigation.nativeElement.innerHTML = this.currentNavigation4;
    });
  }

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    //console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  showPopup(): void {
    let param = {};
    if(this.userInfo.USERID === 'vendor'){
      param = {
        USER_ID: this.userInfo.VENDORUSERID,
        VENDOR_YN:'Y'
      };
    }else{
      param = {
        USER_ID: this.userInfo.USERID,
        VENDOR_YN:'N'
      };
    }
    const dialogRef = this._matDialog.open(PwchangeComponent, {
      data: param
    });
    return;
  }

  async renewToken(): Promise<any> {
    try {

      if (this.remainTime > 0) {
        await this._auth.rebirthToken().toPromise();
        this.remainTime = REMAIN_TIME
      } else {
        this._auth.logout();
      }
    } catch (err) {
      Promise.reject(err);
    }
  }

  notiCheck() {
    this.isNotice = !this.isNotice;
  }
}