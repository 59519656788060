import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap, debounceTime, takeLast, last, take, switchMap, filter } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {


  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor(public _auth: AuthService) { }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): any {

    // 라스트액션 시점을 판단하여, 유효하면 리프래싱을 수락하고, 그렇지 않으면
    // 앨럿창을 찍어주고 로그아웃 시킨다.
    //console.log('리버스 기준에 부합하여 리프레싱 시작=>', this.isRefreshing);
    if (!this.isRefreshing) {
      //console.log('유효한 리프래시 토큰 요청. 진입')
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this._auth.rebirthToken().pipe(
        switchMap((tokens: any) => {
          //console.log('리프래시 토큰 확보함', tokens);
          this.isRefreshing = false;
          this.refreshTokenSubject.next(tokens);
          //console.log('리프래시 토큰 확보한 상태에서 req 다시 요청', req.url, tokens);
          return next.handle(this.addToken(req, tokens));
        })
      );
    } else {
      this.isRefreshing = false;
      return this.refreshTokenSubject.pipe(
        filter(tokens => tokens != null),
        take(1),
        switchMap(tokens => {
          this.isRefreshing = false;
          return next.handle(this.addToken(req, tokens));
        }));
    }
  }


  private addToken(req: HttpRequest<any>, tokens: any): HttpRequest<any> {

    if (tokens && tokens.token) {
      //console.log('토큰 =>', tokens.token);
      //req.headers.set(`Authorization`, `Bearer ${tokens.token}`);
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${tokens.token}`,
        },
      });
    }
    if (tokens && tokens.extratoken) {
      //this._auth.extratoken = extraUser;
      //console.log('엑스트라토큰 =>', tokens.extratoken);
      //req.headers.set(`AuthorizationExt`, `Bearer ${tokens.extratoken}`);
      req = req.clone({
        setHeaders: {
          AuthorizationExt: `Bearer ${tokens.extratoken}`,
        },
      });
    }
    //console.log('-----------------------------------------------');
    //console.log('요청URL', req.url)
    //console.log('토큰스.토큰 :', tokens.token);
    //console.log('엑스트라토큰:', tokens.extratoken);
    return req;
  }


  /** 인증 토큰 여부를 체크해서 토큰을 헤더에 담아서 다음 인터셉터로 보내는 역할을 한다.*/
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | any {

    if (this._auth.getJwtToken()) {
      req = this.addToken(req, this._auth.getJwtToken());
    }
    //console.log('요청받음', req.url, new Date().getTime() - this._auth.lastActionTime);
    return next.handle(req).pipe(


      tap(evt => {
        if (evt instanceof HttpResponse) {
          //console.log('응답받았다, 정상적으로', req.url, req.headers, evt);
          if (!req.url.includes('app-noti') && !req.url.includes('prod-version')) {
            this._auth.lastActionTime = new Date().getTime();
            this._auth.refreshTimeSubject.next('');
          }
          // refreshtoken call 해서 처리함.
        }
      }),
      catchError((error: any) => {
        //console.log('에러가 들어옴.', error);

        // 리프래시요청이 들어온 상태에서 에러였다면 여기서 튕겨줘야 함.
        // 만약 refreshToken에 대한 요청이었고 그것이 실패된 것이라면, 

        if (req.url.includes('token-check')) {
          this._auth.removeToken();
          return next.handle(req);
        }
        if (req.url.includes('refresh-token-rebirth')) {
          this._auth.logout();
        }

        if (req.url.includes('login')) {
          return throwError(error.error);
          // return throwError('해당 아이디에 대한 로그인 정보가 확인되지 않습니다. 다시 시도해주세요.');
        }

        if (error instanceof HttpErrorResponse && error.status === 401) {
          //console.log('인증관련 에러임', error);
          // 두가지 케이스 토큰이 변조되었거나, 세션타임아웃이 걸린 케이스.
          //console.log(new Date().getTime() - this._auth.lastActionTime);
          // 현재시간 - 마지막 작업시간을 빼면, 그 값이 점점 커지는 형태임. 30분 이내로 확인이 된다면,
          // 401 에러 메시지에 대한 토큰 부활 기회를 준다. 만약 30분을 초과하였으면,
          // 이미 창이 닫혔을 것이고, 따로 기회를 부여하지 않도록 한다.
          if (new Date().getTime() - this._auth.lastActionTime < 1000 * 60 * 60) {
            // 마지막 요청 시간을 기준으로 아직 유효한 토큰으로 간주되는 경우라면
            return this.handle401Error(req, next);
          } else {
            // 유효시간을 초과 하였으므로, 에러 메시지 그대로 보여주고 로그아웃 처리함.
            //console.log(`유효시간을 초과 하였으므로, 에러 메시지 그대로 보여주고 로그아웃 처리함.`, this._auth.lastActionTime, new Date().getTime() - (this._auth.lastActionTime || 0) / 1000 * 60);
            //alert('유효시간 초과되었습니다, 다시 로그인 해주세요');
            this._auth.logout();
            return throwError(error);
          }
        } else {
          //console.log('인증아닌 에러가 들어옴', error);
          //alert('서버사이드 오류입니다. 오류가 반복될 시 관리자에 문의해주세요.');
          //this._auth.logout();
          console.log('서버사이드 오류입니다. 오류 반복시 관리자에 문의주세요', error);
          return throwError(error);
        }
      }));
  }
}