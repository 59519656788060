import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { alert } from 'devextreme/ui/dialog';
import { REMAIN_TIME } from '../app-init.service';

/**
 *
 * 사용자 인증 토큰 유효성 체크 및
 * 메뉴 권한 가져오는 용도.
 *
 */
@Injectable()
export class AuthService {


  private readonly USER_TOKEN = 'token';
  private readonly EXTRA_TOKEN = 'extratoken';
  

  getJwtToken() {
      const token = JSON.parse(localStorage.getItem('token'));
      const extratoken = JSON.parse(localStorage.getItem('extratoken'));
      return { token, extratoken }
  }
  
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public refreshTimeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(REMAIN_TIME);

  private currentHostInfoSubject: BehaviorSubject<any>;
  public currentHostInfo: Observable<any>;

  extraUser: any;
  lastActionTime: number = 0;
  remainTime: number = REMAIN_TIME;

  LIMIT_TIME: number = 1000 * 60;

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    _platformLocation: PlatformLocation
    ) {
    /**
     * host ip
     */
    const hostInfo ={
      PROTOCOL: (_platformLocation as any).location.protocol, //"http:"
      HOST    : (_platformLocation as any).location.host,     //"localhost:4200"
      HOSTNAME: (_platformLocation as any).location.hostname, //"localhost"
      PORT    : (_platformLocation as any).location.port,     //"4200"
      ORIGIN  : (_platformLocation as any).location.origin,   //"http://localhost:4200"
    }
    localStorage.setItem('currentHostInfo', JSON.stringify(hostInfo));
    sessionStorage.setItem('currentHostInfo', JSON.stringify(hostInfo));

    this.currentHostInfoSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentHostInfo'))
    );
    this.currentHostInfo = this.currentHostInfoSubject.asObservable();

    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentHostInfoValue(): any {
    return this.currentHostInfoSubject.value;
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }
  
  rebirthToken() {
    return this.httpClient
      .post<any>(`${environment.baseUrl}/v1/refresh-token-rebirth`, {})
      .pipe(
        tap((resultTokens: any) => {
          if (resultTokens && resultTokens.token && resultTokens.extratoken) {
            localStorage.setItem('token', JSON.stringify(resultTokens.token));
            localStorage.setItem('extratoken', JSON.stringify(resultTokens.extratoken));
          }
        }));
  }


  /**
   * 로그인 폼으로부터 데이터를 받은 다음에 로그인을 진행한 다음
   * 결과 값을 user로 받아온다.
   * user 데이터는 token을 보유하고 있어야 하고, 토큰 유무를 판단하여
   * 로컬스토리지에 저장을 한다.
   * @param formData
   */
  login(formData: any): Observable<any> {
    //console.log('login formData===>', formData);
    return this.httpClient.post<any>(`${environment.baseUrl}/v1/login`, formData).pipe(
      map(user => {
        //console.log('logined user ====>', user);
        // 비밀번호 변경
        if (user && user.PSW_YN === 'Y' && user.ID_YN === 'Y') {
          //console.log('비밀번호 초기화 ===>', user.PSW_YN, user.ID_YN);
        }
        else if (user && user.token) {
          const userInfo = {
            HOTELCODE       : user.HOTELCODE,
            DEPT_CD         : user.DEPT_CD,
            USERID          : user.USERID,
            USERNM          : user.USERNM,
            IPNO            : user.IPNO,
            TMS_USER        : user.TMS_USER,
            AUTH_DEPT_CD    : user.AUTH_DEPT_CD,
            RFQ_HOTEL_CODE  : user.RFQ_HOTEL_CODE,
            token           : user.token,
            extratoken      : user.extratoken
          };
          localStorage.setItem('currentUser', JSON.stringify(userInfo));
          localStorage.setItem('tmsUserOutlets', JSON.stringify(user.TMS_USER_OUTLETS));
          localStorage.setItem('token', JSON.stringify(userInfo.token));
          localStorage.setItem('extratoken', JSON.stringify(userInfo.extratoken));
          //localStorage.setItem('extratoken', JSON.stringify(userInfo.token));
          this.currentUserSubject.next(userInfo);

          /*
          /!**
           * Set config
           *
           * @param value
           * @param {{emitEvent: boolean}} opts
           *!/
          setConfig(value, opts = { emitEvent: true }): void {
            // Get the value from the behavior subject
            let config = this._configSubject.getValue();

          // Merge the new config
          config = _.merge({}, config, value);

          // If emitEvent option is true...
          if (opts.emitEvent === true) {
            // Notify the observers
            this._configSubject.next(config);
          }
        }

          /!**
           * Get config
           *
           * @returns {Observable<any>}
           *!/
          getConfig(): Observable<any> {
            return this._configSubject.asObservable();
        }*/
        }

        return user;
        // 로그인 성공했고, 응답으로 jwt토큰을 가진 유저를 확보한다.
        /*localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);*/
      })
    );
  }

  /**
   * 로그인 폼으로부터 데이터를 받은 다음에 로그인을 진행한 다음
   * 결과 값을 user로 받아온다.
   * user 데이터는 token을 보유하고 있어야 하고, 토큰 유무를 판단하여
   * 로컬스토리지에 저장을 한다.
   * @param formData
   */
  vendorLogin(formData: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.baseUrl}/v1/vendor-login`, formData).pipe(
      map(user => {
        //console.log('vendor logined user ====>', user);
        if (user && user.token) {
          const userInfo = {
            HOTELCODE: user.HOTELCODE,
            USERID: user.USERID,
            USERNM: user.USERNM,
            VENDORUSERID: user.VENDORUSERID,
            VCODE: user.VCODE,
            IPNO: user.IPNO,
            token: user.token,
            extratoken: user.extratoken
          };
          //console.log('userinfo ===>', userInfo);
          localStorage.setItem('currentUser', JSON.stringify(userInfo));
          localStorage.setItem('token', JSON.stringify(userInfo.token));
          localStorage.setItem('extratoken', JSON.stringify(userInfo.extratoken));
          this.currentUserSubject.next(userInfo);

          /*
          /!**
           * Set config
           *
           * @param value
           * @param {{emitEvent: boolean}} opts
           *!/
          setConfig(value, opts = { emitEvent: true }): void {
            // Get the value from the behavior subject
            let config = this._configSubject.getValue();

          // Merge the new config
          config = _.merge({}, config, value);

          // If emitEvent option is true...
          if (opts.emitEvent === true) {
            // Notify the observers
            this._configSubject.next(config);
          }
        }

          /!**
           * Get config
           *
           * @returns {Observable<any>}
           *!/
          getConfig(): Observable<any> {
            return this._configSubject.asObservable();
        }*/
        }

        return user;
        // 로그인 성공했고, 응답으로 jwt토큰을 가진 유저를 확보한다.
        /*localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);*/
      })
    );
  }

  logout(): void | any {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('extraUser');
    localStorage.removeItem('token');
    localStorage.removeItem('extratoken');
    localStorage.removeItem('tmsUserOutlets');
    localStorage.removeItem('currentHostInfo');
    this.currentUserSubject.next(null);
    // 거래처에서 로그인 한 경우 vendor-login으로 navigate 처리.
    if (environment.isVendor) {
        this.router.navigate(['/vendor-login']);
    } else {
        this.router.navigate(['/login']);
    }
  }

  removeToken(): void | any {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('extraUser');
    localStorage.removeItem('token');
    localStorage.removeItem('extratoken');
    localStorage.removeItem('tmsUserOutlets');
    localStorage.removeItem('currentHostInfo');
    this.currentUserSubject.next(null);
    // 거래처에서 로그인 한 경우 vendor-login으로 navigate 처리.
    if (environment.isVendor) {
      location.replace('/vendor-login');
    } else {
      location.replace('/login');
    }
  }

  /*async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    // 메뉴아이디와 사용자 아이디를 기준으로 권한 기준 정보를 확보하여 서비스에 반영토록한다.
    //console.log('route ====> ', route);
    //console.log('state ====> ', state);

    return new Promise((resolve, reject) => {
      Promise.all([
        this.getCurrentUserAuthStatus(state.url.substr(state.url.indexOf('w') + 1)),
      ]).then(
        ([]) => {
          resolve();
        },
        err => {
          //console.log('err ====>', err.error);

          this.router.navigate([`/pages/auth/login`]).then(() => {
            this._ui.showToast(err.error);
          });
        }
      );
    });
  }
*/
  /*  getCurrentUserAuthStatus(menuId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpClient
        .get<any>(`${environment.baseUrl}/v1/auth-status/${menuId}`)
        .pipe(
          map(data => {
            const rows: any[] = data.rows;
            return rows.map(el => {
              return el['FUNCTION_CODE'];
            });
          }),
          tap(data => {
            //console.log('중간점검 ====> ', data);
          })
        )
        .subscribe(result => {
          this.authStatus = {};
          result.map(el => {
            this.authStatus['' + el] = true;
          });
          this.onChangedAuthStatus.next(this.authStatus);
          resolve();
        }, reject);
    });
  }

  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      //console.log('check===>', this._cookieService.check('jwt_token'));
      if (this._cookieService.check('jwt_token')) {
        this._cookieService.delete('jwt_token', '/', 'localhost');
        this._cookieService.delete('user_info', '/', 'localhost');
      }
      resolve('로그아웃 완료');
    });
  }*/

  /*// 현 시점의 메뉴와 그에 해당하는 권한 정보를 확보한다.(실시간으로 가져오는 것도 괜찮다.
  // 인터셉트에서 구현한 인증 정보를 가져오는 것도 좋음.
  getToken(): string {
    return this._cookieService.get('jwt_token') || '';
  }*/
  changePw(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(`${environment.baseUrl}/v1/change-pw`, payload)
        .subscribe(
          rslt => {
            resolve(rslt);
          },
          err => {
            reject(err);
            // alert(err, '오류')
          }
        );
    });
  }

  changeVendorPw(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(`${environment.baseUrl}/v1/change-vend-pw`, payload)
        .subscribe(
          rslt => {
            resolve(rslt);
          },
          err => {
            reject(err);
            // alert(err, '오류')
          }
        );
    });
  }
  //   this.httpClient.post<any>(`${environment.baseUrl}/v1/change-pw`, payload).pipe(
  //     map(user => {
  //       //console.log('logined user ====>', user);
  //        // 비밀번호 변경
  //        if(user && user.PSW_YN === 'Y'){
  //         //console.log('비밀번호 초기화 ===>', user.PSW_YN);
  //       }
  //       else if (user && user.token) {
  //         const userInfo = {
  //           HOTELCODE: user.HOTELCODE,
  //           USERID: user.USERID,
  //           USERNM: user.USERNM,
  //           IPNO: user.IPNO,
  //           TMS_USER: user.TMS_USER,
  //           token: user.token
  //         };


  //         //console.log('userinfo ===>', userInfo);
  //         localStorage.setItem('currentUser', JSON.stringify(userInfo));
  //         localStorage.setItem('tmsUserOutlets', JSON.stringify(user.TMS_USER_OUTLETS));
  //         localStorage.setItem('token', JSON.stringify(userInfo.token));
  //         this.currentUserSubject.next(userInfo);


  //       }

  //       return user;
  //       // 로그인 성공했고, 응답으로 jwt토큰을 가진 유저를 확보한다.
  //       /*localStorage.setItem('currentUser', JSON.stringify(user));
  //       this.currentUserSubject.next(user);*/
  //     })
  //   );
  // }

}
