import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../../../common/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUIService } from '../../../common/common-ui.service';
import { FuseNavigationService } from '../../../../@fuse/components/navigation/navigation.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'vendor-login-candidate',
    templateUrl: './vendor-login-candidate.component.html',
    styleUrls: ['./vendor-login-candidate.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class VendorLoginCandidateComponent implements OnInit {
    loginForm: FormGroup;
    returnUrl: string;
    error = '';

    isVendPopupVisible = false;
    isNoticePopupVisible = false;
    isQnaPopupVisible = false;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _auth: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private _ui: CommonUIService,
        private _fuseNavigationService: FuseNavigationService
    ) {
        this._fuseConfigService.setConfig({
            layout: {
                navbar: {
                    hidden: true,
                },
                actionbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        });
        if (this._auth.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            pid: ['', [Validators.required]],
            ppass: ['', Validators.required],
        });

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    login(loginForm: FormGroup): void {
        this._auth
            .vendorLogin(loginForm.getRawValue())
            .pipe(first())
            .subscribe(
                data => {
                    this._fuseNavigationService.unregister('main');
                    this._fuseNavigationService.register(
                        'main',
                        this._fuseNavigationService.generateNavigation(data.MENU)
                    );
                    // Set the main navigation as our current navigation
                    this._fuseNavigationService.setCurrentNavigation('main');
                    this.router.navigate([this.returnUrl]).then(() => {
                        if (this.returnUrl === '/' || this.returnUrl.includes('/main')) {
                            this._fuseConfigService.setConfig({
                                layout: {
                                    navbar: {
                                        hidden: false,
                                    },
                                    actionbar: {
                                        hidden: true,
                                    },
                                    toolbar: {
                                        hidden: false,
                                    },
                                    footer: {
                                        hidden: true,
                                    },
                                    sidepanel: {
                                        hidden: false,
                                    },
                                },
                            });
                        } else {
                            this._fuseConfigService.resetToDefaults();
                        }
                    });
                },
                error => {
                    this._ui.showToast(error);
                }
            );
    }

    showVendPopup(): void {
        console.log('showVendPopup isVendPopupVisible==>',this.isVendPopupVisible);
        this.isVendPopupVisible = true;
    }

    showQnaPopup(): void {
        this.isQnaPopupVisible = true;
    }
}
