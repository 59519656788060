import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject, Subject, Subscriber, of } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable()
export class OtpService {
    constructor(
        private httpClient: HttpClient,
    ) {
    }

    otpRequest(params: any): Promise<any> {
        /**
         * 성공 :   {"success":"true"}
         * 실패 :   {"success":"false","err_msg":"등록되지 않은 사용자입니다."}
                    {"success":"false","err_msg":"OTP 전송을 실패했습니다."}
                    {"success":"false","err_msg":"Parameter Error"}
         */
        return new Promise((resolve, reject) => {
            this.httpClient.get<any>(`${environment.baseUrl}/v1/cmm/otpRequest?LOGIN_ID=${params.LOGIN_ID}`).subscribe(
                result => {
                    resolve(result);
                },
                err => reject(err)
            );
        });
    }

    otpResponse(params: any): Promise<any> {
        /**
         * 성공 :   {"success":"true"}
         * 실패 :   {"success":"false","err_msg":"등록되지 않은 사용자입니다."}
                    {"success":"false","err_msg":"OTP 인증 번호가 틀립니다."}
                    {"success":"false","err_msg":"이미 사용한 OTP 인증 번호입니다."}
                    {"success":"false","err_msg":"OTP 인증 번호의 사용 시간이 만료되었습니다. 다시 시도해주세요."}
                    {"success":"false","err_msg":"OTP 인증 번호 전송을 위한 매체가 지정되지 않았습니다."}
                    {"success":"false","err_msg":"중지 사용자 입니다. 관리자에게 문의 바랍니다."}
                    {"success":"false","err_msg":"OTP를 사용 가능한 시간이 아닙니다."}
                    {"success":"false","err_msg":"OTP를 사용 가능한 시간이 종료되었습니다."}
                    {"success":"false","err_msg":"OTP를 찾을 수 없습니다."}
                    {"success":"false","err_msg":"OTP 인증을 실패하였습니다."}
                    {"success":"false","err_msg":"OTP 인증이 [#]회 실패되었습니다. [#]회 실패시 계정이 잠깁니다."}
                    {"success":"false","err_msg":"OTP 인증이 [#]회 실패로 계정이 잠겼습니다. 관리자에게 문의 바랍니다. "}
                    {"success":"false","err_msg":"Server is down."}
         */
        // return of(JSON.parse(`{"success":"true"}`));
        return new Promise((resolve, reject) => {
            this.httpClient.get<any>(`${environment.baseUrl}/v1/cmm/otpResponse?LOGIN_ID=${params.LOGIN_ID}&OTP_NO=${params.OTP_NO}`).subscribe(
                result => {
                    resolve(result);
                },
                err => reject(err)
            );
        });
    }

    resetPassword(params: any): Observable<any> {
        return this.httpClient.post<any>(`${environment.baseUrl}/v1/cmm/resetPassword`, params);
    }
}
