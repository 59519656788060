import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FuseNavigationService } from '../../../../@fuse/components/navigation/navigation.service';
import { ActionBarService, ActionBtnType } from './action-bar.service';
import { Subject } from 'rxjs';
import { AuthService } from '../../../common/auth.service';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'actionbar',
  templateUrl: './actionbar.component.html',
  styleUrls: ['./actionbar.component.scss'],
})
export class ActionbarComponent implements OnInit {
  ActionType = ActionBtnType;

  actionBtnStatus: any = {};

  private _unsubscribeAll: Subject<any>;

  // 사용자 권한에 따른 메뉴 활성/비활성 외 로직에 따른 처리도 고려해야 함.
  // 액션 바 컨피규레이션 지정
  // 네비게이션 서비스 스타일로 제어 활용.

  // 해당 URL에 접근했을 때, MENUID를 기준으로 사용 가능한 펑션 리스트를 체크하여
  // 활성 비활성 처리를 한다.
  // 1. 현재의 URL정보를 확인한다.
  // 2. 사용자 authStatus로부터 펑션 권한 중 기본 펑션 유무를 확인한다.
  // 3. 펑션 여하에 따라 해당 버튼에 true/ false 처리를 지정한다.

  constructor(
    private _fuseNavigationService: FuseNavigationService,
    private _actionBarService: ActionBarService,
    private _authService: AuthService,
    private _router: Router
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.actionBtnStatus = {};

    this._actionBarService.onChangedAuthStatus.subscribe(r => {
      if (r) {
        this.actionBtnStatus = r;
      }
    });
    /*
    this._router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // 메뉴 권한 정보를 가져온다.
        // 현 시점의 URL정보와 사용자 정보를 가지고 호출
        const url = this._router.url;
        if (url.includes('login') || url.includes('main')) return;
        //console.log('url =====>', url);
        this._actionBarService.getCurrentUserAuthStatus(url.substr(url.indexOf('w') + 1))
          .then((actionBarAuthStatus) => {
            if (actionBarAuthStatus) {
              //console.log('ActionBarStatus =====>', actionBarAuthStatus);
              this.actionBtnStatus = actionBarAuthStatus;
            }
          });
      });*/
    // 액션바는 서비스로 부터 액션바의 권한 정보를 얻어온다.
    // 액션바 서비스는 액션바 상태 정보를 지닌다.
    // 라우팅이 될 때마다 액션바 서비스가 동작하여 메뉴 정보를 획득한다.

    // Scroll to the active item position
  }

  ngOnInit() {
    // 라우팅이 끝나는 시점에 해당 사용자의 메뉴별 권한을 가져와서 지정토록 한다.
    /*this._router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // 메뉴 권한 정보를 가져온다.
        // 현 시점의 URL정보와 사용자 정보를 가지고 호출
        const url = this._router.url;
        if (url.includes('login') || url.includes('main')) return;
        //console.log('url =====>', url);
        this._actionBarService.getCurrentUserAuthStatus(url.substr(url.indexOf('w') + 1))
          .then((actionBarAuthStatus) => {
            if (actionBarAuthStatus) {
              //console.log('ActionBarStatus =====>', actionBarAuthStatus);
              this.actionBtnStatus = actionBarAuthStatus;
            }
          });
      });
*/
  }

  btnClick(actionType) {
    this._actionBarService.onClickedActionBtn.next(actionType);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
