import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { DxCheckBoxModule, DxFormModule, DxPopupModule } from 'devextreme-angular';

import { VendorLoginComponent } from './vendor-login.component';

import { QnaModule } from '../../board/qna/qna.module';
import { VendorModule } from '../../board/vendor/vendor.module';

@NgModule({
    declarations: [
        VendorLoginComponent
    ],
    imports: [
        RouterModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,

        FuseSharedModule,

        DxCheckBoxModule,
        DxFormModule,
        DxPopupModule,

        QnaModule,
        VendorModule,
    ],
})
export class VendorLoginModule { }
