import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/authentication/login/login.component';
import { LogoutComponent } from './pages/authentication/logout/logout.component';
import { VendorLoginComponent } from './pages/authentication/vendor-login/vendor-login.component';
import { VendorLoginCandidateComponent } from './pages/authentication/vendor-login-candidate/vendor-login-candidate.component';
import { VendorLogoutComponent } from './pages/authentication/vendor-logout/vendor-logout.component';
import { Error404Component } from './pages/errors/404/error-404.component';
import { Error500Component } from './pages/errors/500/error-500.component';
import { AuthGuard } from './common/auth-guard';
import { environment } from '../environments/environment';
import { PwchangeComponent } from './pages/authentication/pwchange/pwchange.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: environment.isVendor === true ? '/bid/m2-9001/m3-w9019' : (environment.isTms === true ? '/tms/m2-3111/m3-w9015' : '/po/m2-3020/m3-w4294'),
    pathMatch: 'full',
  },
  {
    path: 'cmm',
    loadChildren: './menu/cmm/cmm.module#CmmModule',
    canActivateChild: [AuthGuard],
  },
  {
    path: 'sys',
    loadChildren: './menu/sys/sys.module#SysModule',
    canActivateChild: [AuthGuard],
  },
  {
    path: 'hr',
    loadChildren: './menu/hr/hr.module#HrModule',
    canActivateChild: [AuthGuard],
  },
  {
    path: 'pay',
    loadChildren: './menu/pay/pay.module#PayModule',
    canActivateChild: [AuthGuard],
  },
  {
    path: 'tms',
    loadChildren: './menu/tms/tms.module#TmsModule',
    canActivateChild: [AuthGuard],
  },
  {
    path: 'cms',
    loadChildren: './menu/cms/cms.module#CmsModule',
    canActivateChild: [AuthGuard],
  },
  {
    path: 'po',
    loadChildren: './menu/po/po.module#PoModule',
    canActivateChild: [AuthGuard],
  },
  {
    path: 'cost',
    loadChildren: './menu/cost/cost.module#CostModule',
    canActivateChild: [AuthGuard],
  },
  {
    path: 'mat',
    loadChildren: './menu/mat/mat.module#MatModule',
    canActivateChild: [AuthGuard],
  },
  {
    path: 'bid',
    loadChildren: './menu/bid/bid.module#BidModule',
    canActivateChild: [AuthGuard],
  },
  {
    path: 'tem',
    loadChildren: './menu/tem/tem.module#TemModule',
    canActivateChild: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  // {
  //   path: 'findPw',
  //   component: PwchangeComponent,
  // },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'vendor-login',
    component: VendorLoginComponent,
  },
  {
    path: 'vendor-login-candidate',
    component: VendorLoginCandidateComponent,
  },
  {
    path: 'vendor-logout',
    component: VendorLogoutComponent,
  },
  {
    path: 'error-404',
    component: Error404Component,
  },
  {
    path: 'error-500',
    component: Error500Component,
  },
  {
    path: '**',
    redirectTo: '/error-404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
