import { Component, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '../../../../@fuse/services/config.service';

@Component({
  selector: 'error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Error404Component {
  constructor(private _fuseConfigService: FuseConfigService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        actionbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }
}
