import { Component } from '@angular/core';
import { AppInitService } from '../../../app-init.service';
import { FuseConfigService } from '../../../../@fuse/services/config.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  app_noti: any;
  /**
   * Constructor
   */
  constructor(private _appService: AppInitService, private _fuseConfigService: FuseConfigService) {

    if (this._appService.appNoti && this._appService.appNoti.NOTI && !this._fuseConfigService.isFooterClose) {
      this.app_noti = this._appService.appNoti.NOTI.length > 70 ? this._appService.appNoti.NOTI.substr(0, 70) : this._appService.appNoti.NOTI;
    }
    //this.app_noti = this._appService.appNoti.NOTI;
  }

  itsOk() {
    this._fuseConfigService.isFooterClose = true;
    this._fuseConfigService.config = {
      layout: {
        footer: {
          hidden: true
        },
      },
    };
  }
}
