import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  HostListener,
  NgZone,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationKorean } from 'app/navigation/i18n/kr';
import { AuthService } from './common/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { ActionBarService } from './layout/components/actionbar/action-bar.service';
import DevExpress from 'devextreme/bundles/dx.all';
import { AppInitService } from './app-init.service';
import { LogoutComponent } from './pages/authentication/logout/logout.component';
import { environment } from '../environments/environment';
import { locale, loadMessages } from "devextreme/localization";

export interface ISetupEditing {
  allowAdding?: boolean;
  allowDeleting?: boolean | Function;
  allowUpdating?: boolean | Function;
  form?: DevExpress.ui.dxFormOptions;
  mode?: 'popup' | 'batch' | 'row';
  popup?: DevExpress.ui.dxPopupOptions;
  refreshMode?: string;
  texts?: {
    addRow?: string;
    cancelAllChanges?: string;
    cancelRowChanges?: string;
    confirmDeleteMessage?: string;
    confirmDeleteTitle?: string;
    deleteRow?: string;
    editRow?: string;
    saveAllChanges?: string;
    saveRowChanges?: string;
    undeleteRow?: string;
    validationCancelChanges?: string;
  };
  useIcons?: boolean;
}


@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  // @HostListener('window:beforeunload') onBeforeUnload() {
  //   localStorage.removeItem('currentUser');
  //   localStorage.removeItem('extraUser');
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('extratoken');
  //   localStorage.removeItem('tmsUserOutlets');  
  //   localStorage.removeItem('currentHostInfo');
  // } 

  fuseConfig: any;
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  public version: string = environment.version;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _auth: AuthService,
    private _router: Router,
    private _actionBarService: ActionBarService,
    private _appService: AppInitService,
    ngZone: NgZone
  ) {
    locale('ko');

    // Get default navigation
    const currentUser = this._auth.currentUserValue;
    if (currentUser && currentUser.token && this._appService.availableMenu.length > 0) {
      this.navigation = this._fuseNavigationService.generateNavigation(
        this._appService.availableMenu
      );

      const icondb = {
        '2235': 'restaurant',
        '2001': 'face',
        '2002': 'money',
        '2003': 'shopping_cart',
        '2005': 'work_outline',
        '2006': 'build',
        '2007': 'wordvrk_outline',
        '2008': 'work',
        '2009': 'trending_up',
        '2020': 'bassignment',
        '2227': 'business',
        '2228': 'person',
        '2233': 'calendar_today',
        '2234': 'store',
      }
      this.navigation.map(el => {
        if (icondb[el.id]) {
          el.icon = icondb[el.id];
          return el;
        }
        return el;
      });
      ////console.log(this.navigation);
      //this.navigation.map( el => {
      //  if (['2235', '2001', '2002', '2003', '2005', '2006', '2007', '2008', '2009', '2020', '2227', '2228', '2233', '2234'].includes(el.id)) {
      //    el.icon = el.id === '2235' ? 'restaurant' : 'app';//테이블예약
      //    el.icon = el.id === '2001' ? 'face' : 'app';//인사관리
      //    el.icon = el.id === '2002' ? 'money' : 'app';//급여관리
      //    el.icon = el.id === '2003' ? 'shopping_cart' : 'app';//구매관리
      //    el.icon = el.id === '2005' ? 'work_outline' : 'app';//일반회계
      //    el.icon = el.id === '2006' ? 'build' : 'app';//시스템관리
      //    el.icon = el.id === '2007' ? 'dvr' : 'app';//자재관리
      //    el.icon = el.id === '2008' ? 'work' : 'app';//영업회계
      //    el.icon = el.id === '2009' ? 'trending_up' : 'app';//원가관리
      //    el.icon = el.id === '2020' ? 'bassignment' : 'app';//EIS
      //    el.icon = el.id === '2227' ? 'business' : 'app';//고정자산
      //    el.icon = el.id === '2228' ? 'person' : 'app';//회원관리
      //    el.icon = el.id === '2233' ? 'calendar_today' : 'app';//연회예약
      //    el.icon = el.id === '2234' ? 'store' : 'app';//하우스키핑
      //  }
      //})
    } else {
      this.navigation = [];
      // this._auth.logout();
    }

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add languages
    this._translateService.addLangs(['en', 'kr']);

    // Set the default language
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationKorean
    );

    // Use a language
    this._translateService.use('en');

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     **/

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.

    setTimeout(() => {
      this._translateService.setDefaultLang('en');
      // this._translateService.setDefaultLang('kr');
    });

    //OTP입력시간 체크로직을 추가해야함.
    /* ngZone.runOutsideAngular(() => {
        const tokenCheck = setInterval(() => {
            console.log('check', new Date().getTime());
            if (new Date().getTime() - this._auth.lastActionTime > 1000 * 25) {
              console.log('5초 전입니다.', new Date().getTime() - this._auth.lastActionTime);
              clearInterval(tokenCheck);    
            }
        }, 1000);
    }); */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    //console.log('ngOnInit');
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });

    this._router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // 메뉴 권한 정보를 가져온다.
        // 현 시점의 URL정보와 사용자 정보를 가지고 호출

        const url = this._router.url;
        if (url.includes('login') || url.includes('logout') || url.includes('main') || url.includes('publishing') || url.includes('w9020')) {
          return;
        }
        //console.log('app.component.url =====>', url);
        this._actionBarService
          .getCurrentUserAuthStatus(url.substr(url.indexOf('w') + 1))
          .then(actionBarAuthStatus => {
            if (actionBarAuthStatus) {
              //console.log('App Component ActionBarStatus =====>', actionBarAuthStatus);
              this._actionBarService.authStatus = actionBarAuthStatus;
              this._actionBarService.onChangedAuthStatus.next(actionBarAuthStatus);
              // this.actionBtnStatus = actionBarAuthStatus;
            }
          });
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

}
