import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { DxFormComponent } from 'devextreme-angular';
import { alert } from 'devextreme/ui/dialog';

import { QnaService } from './qna.service';

@Component({
    selector: 'qna',
    templateUrl: './qna.component.html',
    styleUrls: ['./qna.component.scss'],
})
export class QnaComponent {
    @ViewChild('form') form: DxFormComponent;

    private _isPopupVisible = false;
    // 양방향 바인딩 처리 (속성명을 바꾸면 안됨.)
    @Output() isPopupVisibleChange = new EventEmitter<boolean>();
    @Input()
    get isPopupVisible(): boolean {
        return this._isPopupVisible;
    }
    set isPopupVisible(value: boolean) {
        if(value){
            if(this.form){
                this.form.formData = {
                    HOTEL_CODE: '1000',
                    PI_INFO:
                            `[수집하는 개인정보 항목]
                            회사는 문의에 대해 보다 정확하고 빠른 대응을 위해 아래와 같은 개인정보를 수집하고 있습니다.
                            - 수집항목 : 회사명, 이름, 직함, 연락처, 이메일
                            - 개인정보 수집방법 : 문의 시 문의자가 직접 작성
                            
                            [개인정보의 수집 및 이용목적]
                            회사는 수집한 개인정보를 웹사이트 운영에 있어 보다 향상된 품질 제공의 목적을 위해 활용합니다.
                            
                            [개인정보의 보유 및 이용기간]
                            문의내용에 대한 답변 완료 후, 문의자의 개인정보를 지체없이 파기합니다.
                            `,
                    PI_AGREE: false,
                    BRD_MEMBG: 'N',
                    BRD_TYPE: '',
                    BRD_COMPNAME: '',
                    BRD_REQNAME: '',
                    BRD_REQEMAIL: '',
                    BRD_REQPHONE: '',
                    BRD_COMPITEM: '',
                    BRD_COMPRESULT: '',
                    BRD_COMPINTRO: '',
                    BRD_TITLE: '',
                    BRD_REQUEST: '',
                    BRD_ITEMGROUP: '',
                    BRD_RESNAME: '',
                    BRD_RESPHONE: '',
                    BRD_STATUS: 'R'
                };
                this.treeSelectedRows = [];
            }
        }
        this._isPopupVisible = value;
        this.isPopupVisibleChange.emit(this._isPopupVisible);
    }

    @Output() insertingQna = new EventEmitter<any>();

    /**
     * 폼과 바인딩 되는 속성
     */
    formData = {
        HOTEL_CODE: '1000',
        PI_INFO:
`[수집하는 개인정보 항목]
회사는 문의에 대해 보다 정확하고 빠른 대응을 위해 아래와 같은 개인정보를 수집하고 있습니다.
- 수집항목 : 회사명, 이름, 직함, 연락처, 이메일
- 개인정보 수집방법 : 문의 시 문의자가 직접 작성

[개인정보의 수집 및 이용목적]
회사는 수집한 개인정보를 웹사이트 운영에 있어 보다 향상된 품질 제공의 목적을 위해 활용합니다.

[개인정보의 보유 및 이용기간]
문의내용에 대한 답변 완료 후, 문의자의 개인정보를 지체없이 파기합니다.
`,
        PI_AGREE: false,
        BRD_MEMBG: 'N',
        BRD_TYPE: '',
        BRD_COMPNAME: '',
        BRD_REQNAME: '',
        BRD_REQEMAIL: '',
        BRD_REQPHONE: '',
        BRD_COMPITEM: '',
        BRD_COMPRESULT: '',
        BRD_COMPINTRO: '',
        BRD_TITLE: '',
        BRD_REQUEST: '',
        BRD_ITEMGROUP: '',
        BRD_RESNAME: '',
        BRD_RESPHONE: '',
        BRD_STATUS: 'R'
    };

    /**
     * 구분 룩업 리스트와 바인딩 되는 속성
     */
    // TODO: 공통코드 처리
    boardType: any[] = [
        { BRD_TYPE: 'Q', BRD_TYPE_NAME: '문의' },
        { BRD_TYPE: 'S', BRD_TYPE_NAME: '제안' },
    ];

    /**
     * QNA 팝업폼의 높이와 바인딩 되는 메서드
     */
    getQnaPopupHeight(): number {
        return window.innerHeight * 0.9;
    }

    /**
     * 개인정보 수집 동의 체크박스의 validationCallback과 바인딩 되는 메서드
     * @param e
     */
    isValidPopupPiAgree(e: any): boolean {
        return e.value === true;
    }

    /**
     * 선택한 header  Data
     */
    currentData:any = this.formData;
    /**
     * 첨부파일 리스트
     */
    treeSelectedRows: any[];
    /**
      * 첨부파일 수정 여부
      */
    isUpdating: boolean = false;
    /**
     * Upload Data
     */
    uploadResult:any;

    constructor(
        private service: QnaService,
    ) {

    }

    /**
     * 등록버튼 클릭시
     */
    onInsertButtonClick = (): void => {
        const validationResult = this.form.instance.validate();

        if (validationResult.isValid) {
            this.insertingQna.emit(this.formData);

            const params = Object.assign({}, this.formData);
            delete params.PI_INFO;
            delete params.PI_AGREE;
    
            this.service.insert(params).subscribe(
                result => {
                    if(this.uploadResult !== undefined && this.uploadResult !== null && this.uploadResult !==''){
                        this.currentData.BRD_SEQ = result.newBrdSeq;
                        this.service.insertUploadFile({POHD:this.currentData,FILES:this.uploadResult}).then(result => {
                            alert(`문의 및 제안이 정상적으로 등록 되었습니다.`, '안내');
                            this.isPopupVisible = false;
                        })
                    }else{
                        alert(`문의 및 제안이 정상적으로 등록 되었습니다.`, '안내');
                        this.isPopupVisible = false;
                    }
                },
                error => alert(`문의 및 제안 등록시 아래의 오류 발생\n${error.message}`, '오류')
            );
        }
    }

    /**
     * 닫기버튼 클릭시
     */
    onCloseButtonClick = (): void => {
        this.isPopupVisible = false;
    }

    /**
     * 첨부파일 버튼 클릭시
     * @param e 
     */
     onAddPictureButtonClick(e: any): void {
        e.event.preventDefault();
        e.event.stopPropagation();

        const selectFileElement = document.getElementById('selectFile');
        if (selectFileElement) {
            selectFileElement.click();
        }
    }
    /**
     * 첨부파일이 변경(선택)된 후
     * @param e 
     */
     onImageFileChanged(e: any): void {
        const params = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            params.append('file', e.target.files[i]); //file,imageFiles
        }
        this.service.uploadFile(params)
            .then((result) => {
                this.uploadResult = result;
                if(this.currentData.BRD_SEQ === undefined || this.currentData.BRD_SEQ === null || this.currentData.BRD_SEQ === ''){
                    this.service.retrieveAtchFile(this.uploadResult).then(result => {
                        this.treeSelectedRows = result;
                    });
                }else{
                    this.service.insertUploadFile({POHD:this.currentData,FILES:this.uploadResult}).then(result => {
                        this.service.retrieveUploadFile({
                            HOTEL_CODE: this.currentData.HOTEL_CODE,
                            BRD_SEQ: this.currentData.BRD_SEQ
                        }).then(result => {
                            this.treeSelectedRows = result;
                        });
                    })
                }
            })
            .catch((error: any) => alert(`첨부파일 업로드시 아래의 오류 발생<br><br>${ error.details || error }`, '오류'));
    }
    /**
     * 이미지추가 버튼 클릭시
     * 같은 이미지를 두번 선택하는 경우를 위해
     * @param e
     */
    onImageFileClick(e: any): void {
        e.target.value = null;
    }
    onItemClick(e){
        // let fileUrl = 'https://www.interbidding.co.kr/'+e.itemData.FILE_PATH;
        let fileUrl = 'https://interbidding.co.kr/'+e.itemData.FILE_PATH;
        window.open(fileUrl,'Download');
    }
    onItemDeleting(e){
        if(this.currentData.BRD_SEQ === undefined || this.currentData.BRD_SEQ === null || this.currentData.BRD_SEQ === ''){
            this.uploadResult = this.uploadResult.filter(data => data.UUID !== e.itemData.UUID);
        }else{
            this.service.deleteUploadFile({POHD:this.currentData,FILE_SEQ:e.itemData.FILE_SEQ}).then(result => {
                this.service.retrieveUploadFile({
                    HOTEL_CODE  : this.currentData.HOTEL_CODE,
                    BRD_SEQ      : this.currentData.BRD_SEQ
                }).then(result => {
                    this.treeSelectedRows = result;
                });
            })
        }
    }
}
