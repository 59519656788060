import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../common/auth.service';
import { PlatformLocation } from '@angular/common';

export enum ActionBtnType {
  Search = 'search',
  Insert = 'insert',
  Delete = 'delete',
  Preview = 'preview',
  Print = 'print',
  Save = 'save',
}

export enum AuthFncType {
  RETRIEVE = '101',
  CREATE = '102',
  DELETE = '103',
  SAVE = '104',
  PREVIEW = '105',
  PRINT = '106',
  EXCEL = '107',
}

@Injectable({
  providedIn: 'root',
})
export class ActionBarService {
  onClickedActionBtn: Subject<ActionBtnType>;
  onChangedAuthStatus: BehaviorSubject<any>;
  authStatus: any;

  constructor(
    private httpClient: HttpClient,
    private authService : AuthService,
    _platformLocation: PlatformLocation
  ) {
    this.onClickedActionBtn = new Subject();
    // 액션바의 메뉴별 권한 정보를 관장하는 옵저버 지정.
    this.onChangedAuthStatus = new BehaviorSubject(null);
  }

  getCurrentUserAuthStatus(menuId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpClient
        .get<any>(`${environment.baseUrl}/v1/auth-status/${menuId}`)
        .pipe(
          map(data => {
            const rows: any[] = data.rows;
            return rows.map(el => {
              return el['FUNCTION_CODE'];
            });
          })
        )
        .subscribe(result => {
          this.authStatus = {};
          result.map(el => {
            this.authStatus['' + el] = true;
          });
          /*this.onChangedAuthStatus.next(this.authStatus);*/
          resolve(this.authStatus);
        }, reject);
    });
  }
}
