import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FuseNavigationService } from '../../../../@fuse/components/navigation/navigation.service';

import { AuthService } from '../../../common/auth.service';

@Component({
  selector: 'vendor-logout',
  templateUrl: './vendor-logout.component.html',
  styleUrls: ['./vendor-logout.component.scss'],
})
export class VendorLogoutComponent implements OnInit {
  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _fuseNavigationService: FuseNavigationService
  ) {}

  ngOnInit(): void {
    this._auth.logout();
    this._fuseNavigationService.unregister('main');
    this._router.navigate(['/vendor-login']);
  }
}
