export const locale = {
  lang: 'en',
  data: {
    NAV: {
      COMMON: 'COMMON',
      APPLICATIONS: 'Applications',
      SYSTEM: {
        TITLE: 'SYSTEM',
        MENU: {
          TITLE: 'MENU MANAGER',
        },
      },
    },
  },
};
