import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { FuseNavigationService } from '../../@fuse/components/navigation/navigation.service';
import { FuseUtils } from '../../@fuse/utils';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    protected _auth: AuthService,
    protected router: Router,
    private _fuseNavigation: FuseNavigationService
  ) {
    // 사용자로부터 권한에 대한 데이터를 가져온다.
    /*this.authService.authStatus.subscribe(
      authStatus => (this.currentAuthStatus = authStatus)
    );*/
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this._auth.currentUserValue;

    // //console.log('can Activate  currentUser ====>', currentUser);
    if (currentUser) {
      // 권한을 따지는 지점이다. 추후 작업 예정.
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }
    const loginUrl = environment.isVendor === true ? '/vendor-login' : '/login';
    this.router.navigate([loginUrl]);
    return false;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this._auth.currentUserValue;
    const currentHost = this._auth.currentHostInfo;
    if (currentUser) {
      const url = state.url;
      ////console.log('authguard can activate child state url =====>', url);

      // 사용자가 가진 available Menu에 대하여 해당 키가 있으면 들어가고 없으면 안되는 것으로
      ////console.log('fuse navigation', this._fuseNavigation.getCurrentNavigation());
      const nav = this._fuseNavigation.getCurrentNavigation();
    //   console.log(url.indexOf('w'));
    //   console.log(url.substring(url.indexOf('w') + 1, url.indexOf(';')));
    //   console.log('filtered ====>',FuseUtils.filterArrayByString(nav, url.substr(url.indexOf('w') + 1)));
      if (
        FuseUtils.filterArrayByString(nav, url.substring(url.indexOf('w') + 1, url.indexOf(';'))).length > 0
      ) {
        return true;
      }
      //this.router.navigate(['/main']);
      return false;
    } else if (environment.isVendor === true && state.url === '/bid/m2-9001/m3-w9020') {
      return true;
    }

    if(environment.isVendor){
      if(this.router.url === '/vendor-login-candidate'){
        this.router.navigate(['/vendor-login-candidate']); // Candidate 거래처 등록
      }else{
        this.router.navigate(['/vendor-login']); // 일회성 거래처 등록
      }
    }else{
      this.router.navigate(['/login']);
    }
    // const loginUrl = environment.isVendor === true ? '/vendor-login' : '/login';
    // this.router.navigate([loginUrl]);
    return false;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    //console.log('here canLoad');
    return true;
  }

  /*  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return undefined;
  }*/

  /*canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin();
  }*/

  /*  protected checkLogin(route?: ActivatedRouteSnapshot) {

    let params: any;

    // 컴포넌트 단위 접근시에는 CanActivate를 받으며, 이 때는 롤 매칭 여하를 따져서, 롤은 있으나,
    // 로그인이 안된 경우에 리다이렉트할 라우트 정보까지 확보해서 진행한다.
    if (route) {
      params = { redirectUrl: route.pathFromRoot.map( r => r.url).join('/')};
    }

    // 모듈 단위 접근 시에는 CanLoad 로부터 호출을 받으며, 토큰 여하 및 verified 여부만 확인한다.
    if (!this.currentAuthStatus) {

      this.router.navigate(['page/auth/login', params]).then(
        () => {
          this._ui.showToast('인증 토큰이 확인되지 않습니다. 다시 로그인해주세요.');
        }
      );
      return false;
    }
    return true;
  }*/
}
