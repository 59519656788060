import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../common/auth.service';
import { CommonUIService } from '../../../common/common-ui.service';
import { FuseNavigationService } from '../../../../@fuse/components/navigation/navigation.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private _auth: AuthService,
    private _ui: CommonUIService,
    private _fuseNavigationService: FuseNavigationService
  ) {}

  ngOnInit() {
    this._auth.logout();
    this._fuseNavigationService.unregister('main');

    // 거래처에서 로그인 한 경우 vendor-login으로 navigate 처리.
    if (environment.isVendor) {
        this.router.navigate(['/vendor-login']);
    } else {
        this.router.navigate(['/login']);
    }

    /*this._auth.logout().then(r => {
      this._fuseNavigationService.unregister('loadedmenu');
      this._ui.showToast(r);
      this._router.navigate(['/pages/auth/login']);
    });*/
  }
}
