import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { DxFormComponent,DxPopupComponent } from 'devextreme-angular';
import { alert } from 'devextreme/ui/dialog';

import { OtpService } from './otp.service';

import { CmmService } from '../../../menu/cmm/cmm.service';
import { AuthService } from '../../../common/auth.service';

@Component({
    selector: 'otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss'],
})
export class OtpComponent {
    @ViewChild('otpPopup') otpPopup: DxPopupComponent;
    @ViewChild('form') form: DxFormComponent;
    @ViewChild('otpForm') otpForm: any;

    private _isPopupVisible = false;
    // 양방향 바인딩 처리 (속성명을 바꾸면 안됨.)
    @Output() isPopupVisibleChange = new EventEmitter<boolean>();
    @Input()
    get isPopupVisible(): boolean {
        return this._isPopupVisible;
    }
    set isPopupVisible(value: boolean) {
        this._isPopupVisible = value;
        this.isPopupVisibleChange.emit(this._isPopupVisible);
    }
    /**
     * QNA 팝업폼의 높이와 바인딩 되는 메서드
     */
    getQnaPopupHeight(): number {
        //return window.innerHeight * 0.9;
        return 300;
    }

    /**
     * 개인정보 수집 동의 체크박스의 validationCallback과 바인딩 되는 메서드
     * @param e
     */
    isValidPopupPiAgree(e: any): boolean {
        return e.value === true;
    }

    constructor(
        private service: OtpService,
        private _cmmService: CmmService,
        private _authService: AuthService        
    ) { }
    
    /**
     * OTP 요청 클릭시
     */
    onReqOtpNoButtonClick = (): void => {
        const loginId = this.form.formData.LOGIN_ID;
        if(loginId === undefined || loginId === null || loginId === ''){
            alert('로그인 아이디를 입력하세요.','알림');
        }else{
            let otpData={
                LOGIN_ID : loginId
            }
            this.service.otpRequest(otpData).then(result => {
                if(result.success === "true"){
                    alert('OTP번호가 발송 되었습니다.','알림');
                    this.form.instance.itemOption('LOGIN_ID','disabled',true);
                    this.form.instance.itemOption('OTP_NO','disabled',false);
                }else if(result.success === "false"){
                    alert(result.err_msg,'알림');
                }
            }).catch(error=>{
                alert(error,'오류');
            });
        }
    }
    /**
     * OTP 인증 클릭시
     */
    onResOtpNoButtonClick = (): void => {
        const otpNo = this.form.formData.OTP_NO;
        const loginId = this.form.formData.LOGIN_ID;
        if(loginId === undefined || loginId === null || loginId === ''){
            alert('로그인 아이디를 입력하세요.','알림');
        }else if(otpNo === undefined || otpNo === null || otpNo === ''){
            alert('OTP번호를 입력하세요.','알림');
        }else{
            let otpData={
                OTP_NO : otpNo,
                LOGIN_ID : loginId
            }
            this.service.otpResponse(otpData).then( result => {
                if(result.success === "true"){
                    let loginData = {
                        LOGIN_ID:loginId
                    }
                    this.service.resetPassword(loginData).subscribe( result => {
                        alert(`인증되었습니다.<br/><br/>비밀번호는 사번으로 초기화 되었으니<br/><br/>로그인 후 변경하여 사용하시기 바랍니다.`,'알림');
                        this.form.formData.LOGIN_ID = '';
                        this.form.formData.OTP_NO = '';
                        this.form.instance.itemOption('LOGIN_ID','disabled',false);
                        this.form.instance.itemOption('OTP_NO','disabled',true);
                        this.isPopupVisible = false;
                    });
                }else if(result.success === "false"){
                    alert(result.err_msg,'알림');
                }
            }).catch(error=>{
                alert(error,'오류');
            });
        }
    }

    /**
     * 닫기버튼 클릭시
     */
    onCloseButtonClick = e => {
        this.form.formData.LOGIN_ID = '';
        this.form.formData.OTP_NO = '';
        this.form.instance.itemOption('LOGIN_ID','disabled',false);
        this.form.instance.itemOption('OTP_NO','disabled',true);
        this.isPopupVisible = false;
    }
}