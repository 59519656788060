import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { alert } from 'devextreme/ui/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import 'moment-timezone';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class M3W9015Service {


  smsFlagChecker: BehaviorSubject<any>;

  smsFlagCheckObj: any;

  SMS_AUTO: any;
  OP_MODE: any;
  BEFORE_ST: any;
  AFTER_ST: any;

  smsFlag: any;
  currentDay: any;

  doCheckSms(state: any): Promise<any> {
    let smsFlag = '';
    return new Promise((resolve, reject) => {
      Object.entries(state).map(([key, value]) => {
        ////console.log('k, v => ', k, v);
        this.smsFlagCheckObj = Object.assign(this.smsFlagCheckObj || {}, {
          [key]: value
        });
      });


      // 상태 채우기
      // 자동 케이스 
      if (this.smsFlagCheckObj['SMS_AUTO'] === '1') {

        if (this.smsFlagCheckObj['OP_MODE'] === 'INSERT') {

          if (this.smsFlagCheckObj['BEFORE_ST'] === '') {

            if (this.smsFlagCheckObj['AFTER_ST'] === '1') {
              smsFlag = '00001'; // 예약

            }
          }
        } else {
          // UPDATE
          if (this.smsFlagCheckObj['BEFORE_ST'] === '0') { // 가예약 직후, 
            if (this.smsFlagCheckObj['AFTER_ST'] === '1') {
              smsFlag = '00001'; // 예약

            }
          }
          if (this.smsFlagCheckObj['BEFORE_ST'] === '1') { // 예약 직후, 

            if (this.smsFlagCheckObj['AFTER_ST'] === '1') {

              if (this.smsFlagCheckObj['RESV_CHANGED']) {
                smsFlag = '00002'; // 예약

              }
            }

            if (this.smsFlagCheckObj['AFTER_ST'] === '2') {
              smsFlag = '00004'; // 확인

            }

            if (this.smsFlagCheckObj['AFTER_ST'] === '5') {
              smsFlag = '00003'; // 취소
            }
          }

          if (this.smsFlagCheckObj['BEFORE_ST'] === '7') { // 예약 직후, 

            if (this.smsFlagCheckObj['AFTER_ST'] === '1') {
              smsFlag = '00001'; // 예약
            }
          }
        }
      }
      resolve(smsFlag);
    });
  }


  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getCurrentDay()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  constructor(private _httpClient: HttpClient) {

    // this.smsFlagChecker = new BehaviorSubject({});
    // this.smsFlagChecker.subscribe((state) => {
    //   Object.entries(state).map((k: any, v: any) => {
    //     //console.log('k, v => ', k, v);
    //     Object.assign(this.smsFlagCheckObj, {
    //       [k]: v
    //     });
    //   });


    //   // 상태 채우기
    //   // 자동 케이스 
    //   if (this.smsFlagCheckObj['SMS_AUTO'] === '1') {

    //     if (this.smsFlagCheckObj['OP_MODE'] === 'INSERT') {

    //       if (this.smsFlagCheckObj['BEFORE_ST'] === '') {

    //         if (this.smsFlagCheckObj['AFTER_ST'] === '1') {
    //           this.smsFlag = '00001'; // 예약
    //           return;
    //         }
    //       }
    //     } else {
    //       // UPDATE
    //       if (this.smsFlagCheckObj['BEFORE_ST'] === '0') { // 가예약 직후, 
    //         if (this.smsFlagCheckObj['AFTER_ST'] === '1') {
    //           this.smsFlag = '00001'; // 예약
    //           return;
    //         }
    //       }
    //       if (this.smsFlagCheckObj['BEFORE_ST'] === '1') { // 예약 직후, 

    //         if (this.smsFlagCheckObj['AFTER_ST'] === '1') {

    //           if (this.smsFlagCheckObj['RESV_CHANGED']) {
    //             this.smsFlag = '00002'; // 예약
    //             return;
    //           }
    //         }

    //         if (this.smsFlagCheckObj['AFTER_ST'] === '2') {
    //           this.smsFlag = '00004'; // 확인
    //           return;
    //         }

    //         if (this.smsFlagCheckObj['AFTER_ST'] === '5') {
    //           this.smsFlag = '00003'; // 취소
    //           return;
    //         }
    //       }

    //       if (this.smsFlagCheckObj['BEFORE_ST'] === '7') { // 예약 직후, 

    //         if (this.smsFlagCheckObj['AFTER_ST'] === '1') {
    //           this.smsFlag = '00001'; // 예약
    //           return;
    //         }
    //       }
    //     }
    //   }
    //   this.smsFlag = '';
    //   return;
    // });
  }

  async getCurrentDay(): Promise<any> {
    try {
      const currentDay = await new Promise((resolve, reject) => {
        this._httpClient
          .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/currentday`, {})
          .subscribe(
            rslt => {
              if(rslt && rslt.rows && rslt.rows.length > 0) {
                resolve(rslt.rows[0]);
              } else {
                resolve(
                  {
                    C_YYYYMMDD: moment(new Date).tz('Asia/Seoul').format('YYYY-MM-DD'),
                    C_YYMMDDHH24MISS: moment(new Date).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
                  });
              }
            },
            err => {
              reject(err);
            }
          );
      });  
      this.currentDay = currentDay;
    } catch(err) {
      return Promise.resolve(
        {
          C_YYYYMMDD: moment(new Date).tz('Asia/Seoul').format('YYYY-MM-DD'),
          C_YYMMDDHH24MISS: moment(new Date).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
        });
    }
  }


  /**
   * 팝업 띄울시 초기화 코드 가져오기 
   * */ 
  getCommonCodeSetting(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/common-code-setting`, {})
        .subscribe(
          rslt => {
            resolve(rslt);
          },
          err => {
            reject(err);
            alert(err, '오류');
          }
        )
    })
  }


  doReservation(payload: any): Promise<any> {
    //console.log('저장값: ', payload);
    Object.assign(payload, {
      SMS_FLAG: this.smsFlag
    })
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/insert-reservation`, payload)
        .subscribe(
          rslt => {
            resolve(rslt);
          },
          err => {
            reject(err);
            alert(err, '오류');
          }
        );
    });
  }

  updateReservation(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/update-reservation`, {
          combinedContext: payload,
          keys: ['HOTEL_CODE', 'SEQ', 'YYMMSSSSS', 'OUTLET_CODE']
        })
        .subscribe(
          rslt => {
            resolve(rslt);
          },
          err => {
            reject(err);
          }
        );
    })
  }

  swapOrMoveTable(payload: { sourceTableId: any; targetTableId: any; }) {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/swap-or-move-reservation`, payload)
        .subscribe(
          rslt => {
            resolve(rslt.rowsAffected);
          },
          err => {
            reject(err);
            alert(err, '오류');
          }
        );
    });
  }

  tblImgList: any = {};

  getOutletSms(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-outlet-sms`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  retrievePeriodCodes(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-period-setuplist`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }


  retrieveNowPeriod(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-now-period`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  retrieveTblImg(data: any): Promise<any> {
    //console.log('retrieve param:', data);
    return new Promise((resolve, reject) => {
      this._httpClient.post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9013/retrieve`, data)
        .subscribe(
          result => {
            resolve(result.rows);
          },
          err => {
            return reject(err.message);
          }
        );
    });
  }

  getReservations(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieveReservation`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  getApponitment(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieveAppointment`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  getRetrieveTableSetup(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-table-setup`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  getRetrieveBookingDataMap(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-booking-data-map`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  getRetrieveBookingDataAll(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-booking-data-all`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  getAvailableTableList(payload: any): Promise<any> {
    //console.log('가예약===>', payload);
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/available-table-list`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            //console.log(rows);
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  getToTimeByDuration(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/totime-by-duration`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }


  getRetrieveGuest(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-guest`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  getGuestClass(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-guest-class`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  getResvHistory(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-guest-history`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  getChangeHistory(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-change-history`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }

  getSmsHistory(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-sms-history`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );
    });
  }


  insertGuest(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/insert-guest`, payload)
        .subscribe(
          result => {
            //console.log('update service result => ', result);
            resolve(result.rows[0]['MEMBER_NO']);
          },
          err => {
            //console.log('UPDATE ERR => ', err);
            reject(err);
            alert(err, '오류');
          }
        );
    });
  }


  updateGuest(payload: any): Promise<any> {
    // const combinedContext = Object.assign(payload, data.key);
    // //console.log('update conbined context =>', combinedContext);
    // const keys = Object.keys(data.key);
    return new Promise<any>((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/update-guest`, {
          combinedContext: payload,
          keys: ['HOTEL_CODE', 'MEMBER_NO']
        })
        .subscribe(
          result => {
            //console.log('update service result => ', result);
            result.rowsAffected ? resolve(result.rowsAffected) : reject(result.errorText);
          },
          err => {
            //console.log('UPDATE ERR => ', err);
            reject(err);
            alert(err, '오류');
          }
        );
    });
  }

  insertSms(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/insert-sms`, payload)
        .subscribe(
          result => {
            //console.log('insert sms result => ', result);
            result.rowsAffected ? resolve(result.rowsAffected) : reject(result.errorText);
          },
          err => {
            //console.log('UPDATE ERR => ', err);
            reject(err);
            alert(err, '오류');
          }
        );
    });
  }


  insertMemo(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/insert-memo`, payload)
        .subscribe(
          result => {
            //console.log('insert memo result => ', result);
            result.rowsAffected ? resolve(result.rowsAffected) : reject(result.errorText);
          },
          err => {
            //console.log('UPDATE ERR => ', err);
            reject(err);
            alert(err, '오류');
          }
        );
    });
  }

  updateMemo(payload: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/update-memo`, {
          combinedContext: payload,
          keys: ['HOTEL_CODE', 'OUTLET_CODE', 'MEMO_DATE', 'SEQ']
        })
        .subscribe(
          result => {
            //console.log('update service result => ', result);
            result.rowsAffected ? resolve(result.rowsAffected) : reject(result.errorText);
          },
          err => {
            //console.log('UPDATE ERR => ', err);
            reject(err);
          }
        );
    });

  }

  removeMemo(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/delete-memo`, data)
        .subscribe(
          result => {
            //console.log('update service result => ', result);
            result.rowsAffected ? resolve(result.rowsAffected) : reject(result.errorText);
          },
          err => {
            //console.log('UPDATE ERR => ', err);
            reject(err);
            alert(err, '오류');
          }
        );
    });
  }




  getMemos(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-memos`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );

    });
  }

  getDispCnt(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tms/m2-3111/m3-w9015/retrieve-disp-cnt`, payload)
        .pipe(map(res => res.rows))
        .subscribe(
          rows => {
            resolve(rows);
          },
          err => reject(err)
        );

    });
  }

  doLogin(payload: any): Promise<any> {
    //console.log('저장값: ', payload);
    //delete payload.OUTLET_CODE;

    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(`${environment.baseUrl}/v1/tmslogin`, payload)
        .subscribe(
          userData => {
            localStorage.setItem('extratoken', JSON.stringify(userData.extratoken));
            //console.log('resolve data ==>', userData);
            resolve(userData.payload);
          },
          err => reject(err)
        );
    });
  }

}

