import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatSnackBarModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { CommonUIService } from './common/common-ui.service';
import { PoModule } from './menu/po/po.module';
import { AuthGuard } from './common/auth-guard';
import { PagesModule } from './pages/pages.module';
import { JwtInterceptor } from './common/jwt-interceptor';
// import { ErrorInterceptor } from './common/error-interceptor';
import { AuthService } from './common/auth.service';
import { AppInitService } from './app-init.service';
import { CmmService } from './menu/cmm/cmm.service';

import { ConstantsService } from './common/services/constants.service';

export function initializeAppImpl(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    PoModule,

    // Page Modules
    PagesModule,
  ],
  providers: [
    CommonUIService,
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorInterceptor,
    //   multi: true,
    // },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppImpl,
      deps: [AppInitService],
      multi: true,
    },
    CmmService,
    ConstantsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
