import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../../../common/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUIService } from '../../../common/common-ui.service';
import { FuseNavigationService } from '../../../../@fuse/components/navigation/navigation.service';
import { NavigationResolver } from '../../../navigation/navigationResolver';
import { environment } from '../../../../environments/environment';
import { first, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { PwchangeComponent } from '../pwchange/pwchange.component';
import { alert } from 'devextreme/ui/dialog';
import { AppInitService } from '../../../app-init.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  returnUrl: string;
  error = '';
  isOtpPopupVisible: Boolean = false;
  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private _ui: CommonUIService,
    private _fuseNavigationService: FuseNavigationService,
    public _matDialog: MatDialog,
  ) {
    this._fuseConfigService.setConfig({
      layout: {
        navbar: {
          hidden: true,
        },
        actionbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    });
    if (this._auth.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      pid: ['', [Validators.required]],
      ppass: ['', Validators.required],
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    //console.log('ngAfterViewInit Call');
  }

  login(loginForm: FormGroup): void {
    this._auth
      .login(loginForm.getRawValue())
      .pipe(first())
      .subscribe(
        data => {
          if (data.PSW_YN && data.ID_YN) {
            if (data.ID_YN === 'Y' && data.PSW_YN === 'Y') {
              //console.log('여기다 팝업===>', data);
              const dialogRef = this._matDialog.open(PwchangeComponent, {
                data: data
              });
              return;
            }
          }
          this._fuseNavigationService.unregister('main');
          this._fuseNavigationService.register(
            'main',
            this._fuseNavigationService.generateNavigation(data.MENU)
          );
          // Set the main navigation as our current navigation
          this._fuseNavigationService.setCurrentNavigation('main');
          this.router.navigate([this.returnUrl]).then(() => {
            if (this.returnUrl === '/' || this.returnUrl.includes('/main')) {
              this._fuseConfigService.setConfig({
                layout: {
                  navbar: {
                    hidden: false,
                  },
                  actionbar: {
                    hidden: true,
                  },
                  toolbar: {
                    hidden: false,
                  },
                  footer: {
                    hidden: true,
                  },
                  sidepanel: {
                    hidden: false,
                  },
                },
              });
            } else {
              this._fuseConfigService.resetToDefaults();
            }
          });
        },
        error => {
          this._ui.showToast(error);
        }
      );
  }

  showOtpPopup(): void {
    this.isOtpPopupVisible = true;
  }
}
