import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { FuseWidgetModule } from '../../../../@fuse/components';
import { SysMaterialModule } from '../../../menu/sys/sys.material.module';

import { 
    DxFormModule, 
    DxFileUploaderModule, 
    DxPopupModule,
    DxListModule
} from 'devextreme-angular';

import { QnaComponent } from './qna.component';
import { QnaService } from './qna.service';

@NgModule({
    declarations: [
        QnaComponent,
    ],
    imports: [
        CommonModule,

        FuseSharedModule,
        FuseWidgetModule,
        SysMaterialModule,

        DxFormModule,
        DxFileUploaderModule,
        DxPopupModule,
        DxListModule
    ],
    exports: [
        QnaComponent,
    ],
    providers: [
        QnaService,
    ],
})
export class QnaModule { }
