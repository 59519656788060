import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable()
export class QnaService {
    constructor(
        private httpClient: HttpClient,
    ) {

    }

    insert(params: any): Observable<any> {
        return this.httpClient.post<any>(`${environment.baseUrl}/v1/bid/m2-9001/m3-w9020/insert`, params);
    }

    retrieveAtchFile(params: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl}/v1/bid/m2-9001/m3-w9020/retrieve-atch-file`, params)
                .subscribe(
                    res => resolve(res.rows),
                    err => reject(err)
                );
        });
    }

    retrieveUploadFile(params: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl}/v1/bid/m2-9001/m3-w9020/retrieve-upload-file`, params)
                .subscribe(
                    res => resolve(res.rows),
                    err => reject(err)
                );
        });
    }

    uploadFile(params: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl}/v1/bid/m2-9001/m3-w9020/file-upload`, params)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    insertUploadFile(params: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl}/v1/bid/m2-9001/m3-w9020/insert-file-upload`, params)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }

    deleteUploadFile(params: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl}/v1/bid/m2-9001/m3-w9020/delete-file-upload`, params)
                .subscribe(
                    res => resolve(res),
                    err => reject(err)
                );
        });
    }
}
